import { call, put } from 'redux-saga/effects';

import {
  GET_CERTIFIED_PRODUCT_LIST_SUCCESS,
  GET_CERTIFIED_PRODUCT_LIST_FALIURE,
  GET_TARGET_USAGE_OPTION_SUCCESS,
  GET_TARGET_USAGE_OPTION_FAILURE,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import axiosApiCall from '../../../../constant/axiosApiCall';
import { CERTIFIED_GET_TARGATE_USAGE_OPTION } from '../../../../constant/Apis';
import { getDcTime } from '../../../../Utils/utils';

export async function handleCertifiedProductList(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCertifiedProductListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(handleCertifiedProductList, '/certified/get-product-list', action.data.payload, token);
    const productList = response.data;
    yield put({ type: GET_CERTIFIED_PRODUCT_LIST_SUCCESS, productList });
  } catch (error) {
    yield put({ type: GET_CERTIFIED_PRODUCT_LIST_FALIURE, error });
  }
}

/* Image shot and document type list */
export async function getCertifiedProductAssetTypeData(url, token, products) {
  return axiosApiCall.post(url, products, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCertifiedProductAssetTypeListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const { productIds } = action.data;
    const response = yield call(getCertifiedProductAssetTypeData, '/asset/certified-asset-type', token, { product: productIds });
    const { data } = response;
    yield put({ type: 'GET_CERTIFIED_PRODUCT_ASSET_TYPE_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'GET_CERTIFIED_PRODUCT_ASSET_TYPE_FAILURE', error });
  }
}

// -----------------------get-target-usage-options------------------------------------
export async function handleGetTargetUsage(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* handleGetTargetUsageSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetTargetUsage, CERTIFIED_GET_TARGATE_USAGE_OPTION, token);
    const targetUsage = response.data.data;
    yield put({ type: GET_TARGET_USAGE_OPTION_SUCCESS, targetUsage });
  } catch (error) {
    yield put({ type: GET_TARGET_USAGE_OPTION_FAILURE, error });
  }
}
