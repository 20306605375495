import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { size, toArray } from 'lodash';
import { connect } from 'react-redux';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';

import UploadItem from '../UploadItem/UploadItem';
import ProgressBarWarning from '../ProgressBarWarning';
import { CLEAR_PROGRESS_BAR } from '../../../redux/actions/Actions';

class UploadProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warningIsOpen: false,
      message: '',
      fileProgressBar: true,
      isDisconnected: false,
    };
  }

  // Internet lost connection code start here
  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('https://google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => clearInterval(webPing));
          })
          .catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  };
  // Internet lost connection code end here

  // to close file upload progress bar
  handleCloseProgressBarModal = (e, fileProgress, t) => {
    e.preventDefault();
    let isAllowedClose = true;
    if (size(fileProgress)) {
      const fileArr = toArray(fileProgress);
      for (let i = 0; i < size(fileProgress); i++) {
        // uploading or validating
        if (fileArr[i].status === 0 || fileArr[i].status === 3) {
          isAllowedClose = false;
          break;
        }
      }
    }

    if (isAllowedClose === false) {
      this.setState({
        warningIsOpen: true,
        message: t(
          'Asset upload is still in progress! Please do not close the popup window',
        ),
      });
    } else {
      this.setState({ fileProgressBar: false });
      this.props.handleCloseFileProgress({});
    }
  };

  // to close progress bar warning
  handleCloseProgressBarWarningModal = () => {
    this.setState({ warningIsOpen: false });
  };

  // to collapse progress bar modal
  handleMinimizeProgressBarModal = () => {
    const card = document.getElementById('progressbar-body');
    const collapseIcon = document.getElementById('progress-bar-collapse-icon');

    if (card.classList.contains('d-block')) {
      card.classList.remove('d-block');
      card.classList.add('d-none');

      collapseIcon.classList.remove('icon-arrow-down22');
      collapseIcon.classList.add('icon-arrow-up22');
    } else {
      card.classList.remove('d-none');
      card.classList.add('d-block');

      collapseIcon.classList.remove('icon-arrow-up22');
      collapseIcon.classList.add('icon-arrow-down22');
    }
  };

  // to open progress bar modal
  handleOpenProgressBarModal = () => {
    const card = document.getElementById('progressbar-body');
    const collapseIcon = document.getElementById('progress-bar-collapse-icon');

    if (card) {
      if (card.classList.contains('d-none')) {
        card.classList.remove('d-none');
        card.classList.add('d-block');

        collapseIcon.classList.remove('icon-arrow-down22');
        collapseIcon.classList.add('icon-arrow-up22');
      }
    }
  };

  render() {
    const { fileProgress, t } = this.props;
    const uploadedFileAmount = size(fileProgress);

    return (
      <>
        <ProgressBarWarning
          warningIsOpen={this.state.warningIsOpen}
          message={this.state.message}
          handleCloseProgressBarWarningModal={
            this.handleCloseProgressBarWarningModal
          }
        />
        <div
          className="card global-card filter-select progress-modal border shadow-md mb-1"
          style={{ display: uploadedFileAmount > 0 ? 'block' : 'none' }}
        >
          <div className="card-header uncertified-product-asset-upload header-elements-inline">
            <a
              href={void 0}
              onClick={(e) => this.handleMinimizeProgressBarModal()}
            >
              <div className="card-title">{t('Uploading File')}</div>
            </a>
            <div className="header-elements">
              <div className="list-icons">
                <a
                  href={void 0}
                  className="list-icons-item"
                  onClick={(e) => this.handleMinimizeProgressBarModal()}
                >
                  <em
                    className="icon-arrow-down22"
                    id="progress-bar-collapse-icon"
                  />
                </a>
                <button
                  type="button"
                  onClick={(e) => this.handleCloseProgressBarModal(e, fileProgress, t)}
                  className="close close-icon"
                >
                  <img src="/assets/images/icons-svg/close.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="card-body uncertified-product-form for-progress loader-popup-body mCustomScrollbar d-block"
            id="progressbar-body"
          >
            {size(fileProgress)
              ? toArray(fileProgress).map((file, f) => (
                <UploadItem
                  key={f}
                  file={file}
                  internetLost={this.state.isDisconnected}
                />
              ))
              : null}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fileProgress: state.fileProgress,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateProgressList: (data) => dispatch({ type: 'UPDATE_ASSET_PROGRESS_BAR_LIST', data }),
  handleCloseFileProgress: (data) => dispatch({ type: CLEAR_PROGRESS_BAR, data }),
});

export default withTranslation()(
  withAuth0(connect(mapStateToProps, mapDispatchToProps)(UploadProgress)),
);
