import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import NotFound from '../Component/NotFound';
import Loader from '../ui/loader/Loader';
import ErrorBoundaries from '../Component/ErrorBoundaries';
import UnAuthorized from '../Component/UnAuthorized';
import componentLoader from '../Utils/componentLoader';

/* -----------------------------dynamic-imports-----------------------------------*/
const Dashboard = lazy(() => componentLoader(() => import('../ChildRoutes/Dashboard')));
const SPDirectLogin = lazy(() => componentLoader(() => import('../Containers/AuthContainer/SPDirectLogin')));
const AuthChild = lazy(() => import('../ChildRoutes/AuthChild'));

function RootRouter() {
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Router>
      <>
        <ErrorBoundaries>
          <Suspense fallback={<div />}>
            {
                            !isAuthenticated
                              ? (
                                <Switch>
                                  <Route path="/" render={(props) => <AuthChild {...props} />} />
                                  <Route path="/sp-login" render={SPDirectLogin} />
                                  <Route path="/403" render={(props) => <UnAuthorized {...props} />} />
                                  <Route path="/404" render={() => <NotFound />} />
                                  <Route render={() => <NotFound />} />
                                </Switch>
                              )
                              : (
                                <Switch>
                                  <Route path="/" render={(props) => <Dashboard {...props} />} />
                                  <Route path="/404" render={() => <NotFound />} />
                                  <Route render={() => <NotFound />} />
                                </Switch>
                              )
                        }
          </Suspense>
        </ErrorBoundaries>
      </>
    </Router>
  );
}

export default RootRouter;
