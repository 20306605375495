import { call, put } from 'redux-saga/effects';

import {
  GET_UNCERTIFIED_PRODUCT_LIST_SUCCESS,
  GET_UNCERTIFIED_PRODUCT_LIST_FALIURE,
  GET_D_CATEGORY_SUCCESS_REQUEST,
  GET_D_CATEGORY_FAILURE_REQUEST,
  GET_UNCERTIFIED_STATUS_SUCCESS_REQUEST,
  GET_UNCERTIFIED_STATUS_FAILURE_REQUEST,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import axiosApiCall from '../../../../constant/axiosApiCall';
import { getDcTime } from '../../../../Utils/utils';

/* -----------------------------------get-product-list-----------------------------------*/

export async function getProductList(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getProductListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(getProductList, '/sde/uncertified-product-list', action.data.payload, token);
    const productList = response.data;
    yield put({ type: GET_UNCERTIFIED_PRODUCT_LIST_SUCCESS, productList });
  } catch (error) {
    yield put({ type: GET_UNCERTIFIED_PRODUCT_LIST_FALIURE, error });
  }
}

/* Image shot type list */
export async function getAssetTypeData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getAssetTypeListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(getAssetTypeData, '/asset/asset-type', token);
    const { data } = response;
    yield put({ type: 'GET_ASSET_TYPE_BY_SELECTED_PRODUCT_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'GET_ASSET_TYPE_BY_SELECTED_PRODUCT_FAILURE', error });
  }
}

/* -----------------------------------get-Category--------------------------------------*/

export function getDynamicCategoryList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getDynamicCategoryListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getDynamicCategoryList, '/sde/get-dynamic-categories', token);
    const dynamicCategoryList = response.data.data;
    yield put({ type: GET_D_CATEGORY_SUCCESS_REQUEST, dynamicCategoryList });
  } catch (error) {
    yield put({ type: GET_D_CATEGORY_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------get-Status--------------------------------------*/

export function getDynamicStatus(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getDynamicStatusSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getDynamicStatus, '/sde/get-dynamic-status', token);
    const dynamicStatusList = response.data.data;
    yield put({ type: GET_UNCERTIFIED_STATUS_SUCCESS_REQUEST, dynamicStatusList });
  } catch (error) {
    yield put({ type: GET_UNCERTIFIED_STATUS_FAILURE_REQUEST, error });
  }
}
