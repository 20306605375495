import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { reducer } from './reducer/Reducer';
import { watcherSaga } from './sagas/watcher-saga/WatcherSaga';

// Enable devtool extension only in development environment
const composeEnhancers = process.env.NODE_ENV === 'development' && typeof window !== 'undefined'
        && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) || compose
  : compose;

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watcherSaga);

export default store;
