import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_SUPPLIER_SPECIFIC_ATTRIBUTE_SUCCESS,
  GET_SUPPLIER_SPECIFIC_ATTRIBUTE_FAILURE,
} from '../../../actions/nbb';
import getIdToken from '../../../../Utils/getIdToken';
import { GET_SUPPLIER_SPECIFIC_ATTRIBUTE } from '../../../../constant/Apis';

// ---------------------------------------handle-subscriptions-request-------------------------------------------
export function getSSAttibutes(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getSSAttibutesSaga(action) {
  try {
    const queryString = `?productId=${action.data.productId}`;
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getSSAttibutes, GET_SUPPLIER_SPECIFIC_ATTRIBUTE + queryString, token);
    const data = response.data.data ? response.data.data?.supplierAttributeData ? response.data.data.supplierAttributeData : null : null;
    yield put({ type: GET_SUPPLIER_SPECIFIC_ATTRIBUTE_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_SUPPLIER_SPECIFIC_ATTRIBUTE_FAILURE, error });
  }
}
