import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_PUBLICATION_SUMMARY_REPORTS_SUCCESS,
  GET_PUBLICATION_SUMMARY_REPORTS_FAILURE,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { GET_PUBLICATION_SUMMARY_REPORTS } from '../../../../constant/Apis';

// ---------------------------------------handle-subscriptions-request-------------------------------------------
export function getPublicationReports(url, token, payload) {
  const formData = new FormData();
  formData.append('loggedinUser', payload.loggedinUser ? payload.loggedinUser : '');
  formData.append('filter[fromDate]', payload?.fromDate ? payload.fromDate : '');
  formData.append('filter[toDate]', payload?.toDate ? payload.toDate : '');
  formData.append('filter[gln]', payload?.gln ? payload.gln : '');
  formData.append('page', payload?.page);
  formData.append('recordsPp', payload?.recordsPp);
  formData.append('sort', payload?.sort);
  formData.append('order', payload?.order);
  formData.append('offset', payload?.offset);

  if (payload?.subCode && payload?.subCode.length !== 0) {
    formData.append('filter[subCode]', JSON.stringify(payload.subCode));
  }

  return axiosApiCall.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getPublicationReportSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getPublicationReports, `${GET_PUBLICATION_SUMMARY_REPORTS}`, token, action.data.payload);
    const { data } = response;
    yield put({ type: GET_PUBLICATION_SUMMARY_REPORTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PUBLICATION_SUMMARY_REPORTS_FAILURE, error });
  }
}
