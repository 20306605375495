import React from 'react';
import { withTranslation } from 'react-i18next';
import './loader.css';

function Loader(props) {
  const { t } = props;
  return (
    <div id="modal-root">
      <div className="spinner-box">
        <div className="loading-icon" />
        <span>{t('Loading...')}</span>
      </div>
    </div>
  );
}

export default withTranslation()(Loader);
