import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/icons/icomoon/styles.css';
import './css/icons/fontawesome/styles.min.css';
import './css/bootstrap.min.css';
import './css/bootstrap_overrides.css';
import './css/layout.css';
import './css/components.css';
import './css/nbb.css';
import './css/custom.css';
import './css/image_topup.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import Auth0ProviderWithHistory from './auth0-provider-with-history';
import 'react-notifications/lib/notifications.css';
import 'rc-tree-select/assets/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-lightbox/style.css';
import './i18next';

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
