import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_UNCERTIFIED_PRODUCT_IMAGES_SUCCESS,
  GET_UNCERTIFIED_PRODUCT_IMAGES_FAILURE,
  GET_UNCERTIFIED_PRODUCT_DOCUMENTS_SUCCESS,
  GET_UNCERTIFIED_PRODUCT_DOCUMENTS_FAILURE,
  GET_DOCUMENT_TYPE_SUCCESS,
  GET_DOCUMENT_TYPE_FAILURE,
  GET_IMAGE_TYPE_SUCCESS,
  GET_IMAGE_TYPE_FAILURE,
  GET_ASSET_EXISTS_SUCCESS,
  GET_DELETE_ASSET_SUCCESS,
  GET_DELETE_ASSET_FAILURE,
} from '../../../actions/Actions';
import store from '../../../Store';
import getIdToken from '../../../../Utils/getIdToken';

// product images list
export function getProductImagesData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getProductImagesSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(
      getProductImagesData,
      `/asset/uncertified-product-images?productId=${action.data.productId}&sortBy=${action.data.sortBy}&order=${action.data.order}`,
      token,
    );
    const images = response.data;
    yield put({ type: GET_UNCERTIFIED_PRODUCT_IMAGES_SUCCESS, images });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_UNCERTIFIED_PRODUCT_IMAGES_FAILURE, errorResponse });
  }
}

// product document list
export function getProductDocumentsData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getProductDocumentsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);

    const response = yield call(
      getProductDocumentsData,
      `/asset/uncertified-product-documents?productId=${action.data.productId}&sortBy=${action.data.sortBy}&order=${action.data.order}`,
      token,
    );
    const documents = response.data;
    yield put({ type: GET_UNCERTIFIED_PRODUCT_DOCUMENTS_SUCCESS, documents });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_UNCERTIFIED_PRODUCT_DOCUMENTS_FAILURE, errorResponse });
  }
}

// document type
export function getDocumentTypeData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getDocumentTypeSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getDocumentTypeData, action.data.documentTypeUrl, token);
    const documentTypeLists = response.data.data;

    yield put({ type: GET_DOCUMENT_TYPE_SUCCESS, documentTypeLists });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_DOCUMENT_TYPE_FAILURE, errorResponse });
  }
}

// image type
export function getImageTypeData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getImageShotTypeSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getImageTypeData, action.data.imageTypeUrl, token);
    const imageTypeLists = response.data.data;

    yield put({ type: GET_IMAGE_TYPE_SUCCESS, imageTypeLists });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_IMAGE_TYPE_FAILURE, errorResponse });
  }
}

// image or document exists
export function getAssetExistsData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getAssetExistsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);

    const api = action.data.apiRequest === 'document' ? 'uncertified-product-document-exists' : 'uncertified-product-image-exists';
    const response = yield call(
      getAssetExistsData,
      `/asset/${api}?productId=${action.data.productId}&type=${action.data.type}&size=${action.data.size}`,
      token,
    );
    const successResponse = response.data.data;
    yield put({ type: GET_ASSET_EXISTS_SUCCESS, successResponse });
  } catch (error) {
    /* const errorResponse = error.response;
        yield put({ type: GET_ASSET_EXISTS_SUCCESS, errorResponse }); */
  }
}

// delete image or document
export function getDeleteAssetsData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getDeleteAssetSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);

    const response = yield call(
      getAssetExistsData,
      `/asset/asset-delete?assetId=${action.data.assetId}&productId=${action.data.productId}&assetType=${action.data.assetType}&index=${action.data.index}`,
      token,
    );

    const data = {
      id: action.data.productId,
      props: action.data.props,
    };
    store.dispatch({ type: 'GET_PRODUCT_DATA_REQUEST', data });

    if (action.data.assetType === 'image') {
      action.data.handleImageList();
    } else {
      action.data.handleDocumentList();
    }

    const successResponse = response.data;
    yield put({ type: GET_DELETE_ASSET_SUCCESS, successResponse });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_DELETE_ASSET_FAILURE, errorResponse });
  }
}
