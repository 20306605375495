import React from 'react';
import { withRouter } from 'react-router';
import { withAuth0 } from '@auth0/auth0-react';
import { withTranslation } from 'react-i18next';

class NotFound extends React.Component {
  UNSAFE_componentWillMount() {
    const { isAuthenticated } = this.props.auth0;
    if (isAuthenticated) {
      if (this.props.location.pathname === '/') {
        const url = window.localStorage.getItem('pastUrl');
        if (url && url !== '/') {
          this.props.history.push(url);
        } else {
          this.props.history.push('/home');
        }
      }
    } else if (this.props.location.search) {
      if (this.props.location.pathname !== '/') {
        window.localStorage.setItem('pastUrl', this.props.location.pathname);
        this.props.history.push(`/login?redirectTo=${this.props.location.pathname}`);
      } else {
        this.props.history.push(`/login/${this.props.location.search}`);
      }
    } else if (this.props.location.pathname === '/403') {
      window.localStorage.removeItem('pastUrl');
      this.props.history.push(this.props.location.pathname);
    } else if (this.props.location.pathname === '/') {
      window.localStorage.removeItem('pastUrl');
      this.props.history.push('/login');
    } else {
      window.localStorage.setItem('pastUrl', this.props.location.pathname);
      this.props.history.push(`/login?redirectTo=${this.props.location.pathname}`);
    }
  }

  handleGoToHome = () => {
    const { isAuthenticated } = this.props.auth0;
    if (isAuthenticated) {
      this.props.history.push('/home');
    } else {
      this.props.history.push('/login');
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div style={{
        height: '100vh', width: '100%', background: '', display: 'flex', alignItems: 'center',
      }}
      >
        <div style={{
          height: 'auto',
          width: '100%',
          background: '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
        >
          <div style={{
            height: 'auto', width: '100%', background: '', marginTop: 30, textAlign: 'center',
          }}
          >
            <h1 style={{ color: '#50c499' }}>
              404 -
              {t('Page Not Found')}
            </h1>
            <button
              onClick={this.handleGoToHome}
              style={{
                height: 45,
                width: 200,
                borderRadius: 50,
                background: '#32c1b9',
                marginTop: 20,
                border: '1px solid transparent',
                color: '#fff',
                cursor: 'pointer',
              }}
              variant="contained"
              color="primary"
            >
              {t('Go To Homepage')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withAuth0(withRouter(NotFound)));
