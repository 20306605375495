import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_CERTIFIED_PRODUCT_IMAGES_SUCCESS,
  GET_CERTIFIED_PRODUCT_IMAGES_FAILURE,
  GET_CERTIFIED_PRODUCT_DOCUMENTS_SUCCESS,
  GET_CERTIFIED_PRODUCT_DOCUMENTS_FAILURE,
  GET_POST_PUBLICATION_PRODUCT_IMAGES_SUCCESS,
  GET_POST_PUBLICATION_PRODUCT_IMAGES_FAILURE,
  GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_SUCCESS,
  GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_FAILURE,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';

export function getCertifiedProductImagesData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function getPostPublicationProductImagesData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getPostPublicationProductImagesSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(
      getPostPublicationProductImagesData,
      `/asset/nbb-product-images?productId=${action.data.productId}&sortBy=${action.data.sortBy}&order=${action.data.order}`,
      token,
    );
    const images = response.data;
    yield put({ type: GET_POST_PUBLICATION_PRODUCT_IMAGES_SUCCESS, images });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_POST_PUBLICATION_PRODUCT_IMAGES_FAILURE, errorResponse });
  }
}

export function* getCertifiedProductImagesSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(
      getCertifiedProductImagesData,
      `/asset/certified-product-images?productId=${action.data.productId}&sortBy=${action.data.sortBy}&order=${action.data.order}`,
      token,
    );
    const images = response.data;
    yield put({ type: GET_CERTIFIED_PRODUCT_IMAGES_SUCCESS, images });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_CERTIFIED_PRODUCT_IMAGES_FAILURE, errorResponse });
  }
}

// document list---------------------------------------------------
export function getCertifiedProductDocumentsData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCertifiedProductDocumentsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);

    const response = yield call(
      getCertifiedProductDocumentsData,
      `/asset/certified-product-documents?productId=${action.data.productId}&sortBy=${action.data.sortBy}&order=${action.data.order}`,
      token,
    );
    const documents = response.data;
    yield put({ type: GET_CERTIFIED_PRODUCT_DOCUMENTS_SUCCESS, documents });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_CERTIFIED_PRODUCT_DOCUMENTS_FAILURE, errorResponse });
  }
}

export function getPostPublicationProductDocumentsData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getPostPublicationProductDocumentsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);

    const response = yield call(
      getPostPublicationProductDocumentsData,
      `/asset/nbb-product-documents?productId=${action.data.productId}&sortBy=${action.data.sortBy}&order=${action.data.order}`,
      token,
    );
    const documents = response.data;
    yield put({ type: GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_SUCCESS, documents });
  } catch (error) {
    const errorResponse = error.response;
    yield put({ type: GET_POST_PUBLICATION_PRODUCT_DOCUMENTS_FAILURE, errorResponse });
  }
}
