import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class ErrorBoundaries extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { t } = this.props;
    if (this.state.errorInfo) {
      return (
        <div className="error_container">
          <div>
            <h2>{t('Something went wrong. Please try again')}</h2>
            <Link
              to="#"
              onClick={() => {
                window.location.reload();
              }}
            >
              <i className="fa fa-refresh" aria-hidden="true" />
              {t('Refresh Page')}
            </Link>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundaries);
