import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_USER_DETAILS_SUCCESS_REQUEST,
  GET_USER_DETAILS_FAILURE_REQUEST,
  GET_NOTIFICATIONS_SUCCESS_REQUEST,
  GET_NOTIFICATIONS_FAILURE_REQUEST,
  GET_SERVICE_MESSAGE_SUCCESS,
  GET_SERVICE_MESSAGE_FAILURE,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';

let isNoticationError = false;

export function getUserDetails(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getUserDetailsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getUserDetails, '/user-details/info', token);
    const user = response.data.data;
    if (user.permission) {
      const { info } = response.data.data;

      let firstName = '';
      let lastName = '';
      if (info.name) {
        firstName = info.name.split(' ').slice(0, -1).join(' ');
        lastName = info.name.split(' ').slice(-1).join(' ');
      }
      if (window.fcWidget) {
        window.fcWidget.user.setProperties({
          email: action.data.auth0.user.email,
          first_name: firstName,
          last_name: lastName,
        });
      }
    }
    yield put({ type: GET_USER_DETAILS_SUCCESS_REQUEST, user });
  } catch (error) {
    yield put({ type: GET_USER_DETAILS_FAILURE_REQUEST, error });
  }
}

export function getNotifications(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getNotificationsSaga(action) {
  if (!isNoticationError) {
    try {
      const token = yield call(getIdToken, action.data.auth0);

      const response = yield call(getNotifications, '/asset/monitor-notifications', token);
      const n = response.data.data;

      yield put({ type: GET_NOTIFICATIONS_SUCCESS_REQUEST, n });
    } catch (error) {
      const msg = error.response;
      isNoticationError = true;
      yield put({ type: GET_NOTIFICATIONS_FAILURE_REQUEST, msg });
    }
  } else {
    return true;
  }
}

export function getServiceMessage(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getServiceMessageSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getServiceMessage, '/user-details/get-service-message', token);
    const messageList = response.data.data;
    yield put({ type: GET_SERVICE_MESSAGE_SUCCESS, messageList });
  } catch (error) {
    yield put({ type: GET_SERVICE_MESSAGE_FAILURE, error });
  }
}
