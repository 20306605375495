import React, { useEffect } from 'react';

function UnAuthorized(props) {
  useEffect(() => {
    if (!props.location.state) {
      // props.history.push('/login');
    }
  });

  return (
    <div className="page-content">
      <div className="content-wrapper custom-home-wrapper">
        <div className="content pt-0">
          <div className="flex-fill">
            <div className="text-center mb-3">

              <div className="Login-logo justify-content-center">
                <img src="/assets/images/spx-logo.svg" alt="NBB Logo icon" />

              </div>
              <h4 className="mb-1">You do not have access to the requested page.</h4>
              <small className="w-50 mx-auto text-muted">
                You are unable to access this page as you do not have the required permissions.
                <br />
                If you believe you should have access to this page please contact support.
              </small>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <a href={process.env.REACT_APP_MY_ACCOUNT_URL} className="btn btn-primary mt-3 mt-sm-0  btn-block mr-2">
                  <em className="icon-user-lock mr-2" />
                  {' '}
                  My Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnAuthorized;
