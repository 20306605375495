import React from 'react';
import { withTranslation } from 'react-i18next';
import { withAuth0 } from '@auth0/auth0-react';
import { Modal } from 'react-bootstrap';

function ProgressBarWarning(props) {
  const { warningIsOpen, message, t } = props;
  return (
    <Modal
      show={warningIsOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
      className="approve-modal"
    >
      <Modal.Header>
        <Modal.Title>
          {t('Warning')}
        </Modal.Title>
        <button type="button" className="close close-icon" onClick={props.handleCloseProgressBarWarningModal}><img src="/assets/images/icons-svg/close.svg" alt="" /></button>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-warning">{message}</div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={props.handleCloseProgressBarWarningModal}>{t('Cancel')}</button>
      </Modal.Footer>
    </Modal>
  );
}
export default withTranslation()(withAuth0(ProgressBarWarning));
