export const isRepeatableByClass = (value = null) => Boolean((value && value.toLowerCase() === 'y') || value == '1');

export const isLanguageEnable = (flag = '0') => Boolean(flag && flag == '1');

export const isRepeatableByValue = (value = null) => {
  let isRepeatable = false;
  if (value) {
    if (value.repeatableByValue == '1'
            || (value.languageEnable == '1' && value.repeatableWithinLang == '1')
            || (value.languageEnable == '1')
            || (value.uomEnable == '1' && (value.repeatableByUOM == '1' || value.repeatableWithinUOM == '1'))) {
      isRepeatable = true;
    }
  }
  return isRepeatable;
};

export const isTPDEnable = (value = null) => Boolean(value && value == '1');

export const maxLengthCheck = (charlength, value) => {
  if (charlength && value.length == charlength) {
    return true;
  }
  return false;
};

export const checkRepetable = (attrDef, key) => !!((attrDef && attrDef[key] == '1'));
