import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import getIdToken from '../../../../Utils/getIdToken';
import {
  GET_CODELIST_SUCCESS_REQUEST,
  GET_CODELIST_FAILURE_REQUEST,
  GET_VALIDATION_DATA_SUCCESS_REQUEST,
  GET_VALIDATION_DATA_FAILURE_REQUEST,
  GET_VALIDATION_LABEL_SUCCESS_REQUEST,
  GET_VALIDATION_LABEL_FAILURE_REQUEST,
  GET_GPC_STATIC_FILE__SUCCESS_REQUEST,
  GET_GPC_STATIC_FILE_FAILURE_REQUEST,
  GET_GROUPS_SUCCESS_REQUEST,
  GET_GROUPS_FAILURE_REQUEST,
} from '../../../actions/nbb';
import * as Constants from '../../../../constant/constant';

// -----------------------------------CODELIST-REQUEST----------------------------------------------------
export function handleGetCodeListData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* handleGetCodeListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetCodeListData, `${Constants.API_URL}/static-file/code-list`, token);
    const { data } = response;
    yield put({ type: GET_CODELIST_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_CODELIST_FAILURE_REQUEST, error });
  }
}

// -----------------------------------VALIDATION-REQUEST----------------------------------------------------
export function handleGetValidationData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* handleGetValidationSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetValidationData, `${Constants.API_URL}/static-file/validation`, token);
    const { data } = response;
    yield put({ type: GET_VALIDATION_DATA_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_VALIDATION_DATA_FAILURE_REQUEST, error });
  }
}

// -----------------------------------VALIDATION-LABEL-REQUEST----------------------------------------------------
export function getValidationLabelData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getValidationLabelSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getValidationLabelData, `${Constants.API_URL}/static-file/validation-label`, token);
    const { data } = response;
    yield put({ type: GET_VALIDATION_LABEL_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_VALIDATION_LABEL_FAILURE_REQUEST, error });
  }
}

// --------------------------------GPC-REQUEST----------------------------------------------------
export function getGpcStaticData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getGpcStaticSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getGpcStaticData, `${Constants.API_URL}/static-file/gpc`, token);
    const { data } = response;
    yield put({ type: GET_GPC_STATIC_FILE__SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_GPC_STATIC_FILE_FAILURE_REQUEST, error });
  }
}

// --------------------------------Groups-REQUEST----------------------------------------------------
export function getGroupsStaticData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getGroupsStaticSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getGroupsStaticData, `${Constants.API_URL}/static-file/groups`, token);
    const { data } = response;
    yield put({ type: GET_GROUPS_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_GROUPS_FAILURE_REQUEST, error });
  }
}
