import { call, put } from 'redux-saga/effects';
import axios from 'axios';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_CONNECT_PLUS_SUCCESS_REQUEST,
  GET_CONNECT_PLUS_FAILURE_REQUEST,
  GET_CONNECT_PLUS_MAIL_SUCCESS_REQUEST,
  GET_CONNECT_PLUS_MAIL_FAILURE_REQUEST,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { WS_CONNECT } from '../../../../constant/constant';

const SubscriptionKey = process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
const audience = process.env.REACT_APP_AUDIENCE;
const scope = process.env.REACT_APP_SCOPE;
const connectPlusApi = process.env.REACT_APP_CONNECT_PLUS_API;

export async function getMailheader(url, token, data) {
  const errorData = {
    errorCode: data?.connectPlusData?.errStatus?.statusCode,
    errorMsg: data?.connectPlusData?.errStatus?.response?.message,
  };

  const payload = {
    email: data.auth0.user.email,
    ...errorData,
  };
  return axiosApiCall.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getMailSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getMailheader, '/connect/mail', token, action.data);
    const n = response.data.data;
    yield put({ type: GET_CONNECT_PLUS_MAIL_SUCCESS_REQUEST, n });
  } catch (error) {
    const msg = error.response;
    error = true;
    yield put({ type: GET_CONNECT_PLUS_MAIL_FAILURE_REQUEST, msg });
  }
}

const getAccessToken = async (data) => {
  try {
    const { getAccessTokenSilently } = data.auth0;
    const token = await getAccessTokenSilently({ audience, scope });
    return token;
  } catch (e) {
    console.log('Invalid Access Token ', e);
  }
};

export async function getConnectPlus(url, token, data) {
  const newToken = await getAccessToken(data);
  const payload = {
    email: data.auth0.user.email,
    url: connectPlusApi,
    services: WS_CONNECT,
    newToken,
    SubscriptionKey,

  };
  return axiosApiCall.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getConnectPlusSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getConnectPlus, '/connect/getConnectPlus', token, action.data);
    const errStatus = response.data.data;
    const { statusCode } = response.data.data;
    if (response && statusCode && statusCode !== 200) {
      yield put({ type: GET_CONNECT_PLUS_FAILURE_REQUEST, errStatus });
    } else {
      const user = response.data.data;
      yield put({ type: GET_CONNECT_PLUS_SUCCESS_REQUEST, user });
    }
  } catch (error) {
    console.log(error);
  }
}
