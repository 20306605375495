import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_TARGET_MARKET_SUCCESS_REQUEST,
  GET_TARGET_MARKET_FAILURE_REQUEST,
  GET_SUBCODES_SUCCESS_REQUEST,
  GET_SUBCODES_FAILURE_REQUEST,
  GET_BRANDS_SUCCESS_REQUEST,
  GET_BRANDS_FAILURE_REQUEST,
  GET_CATEGORY_SUCCESS_REQUEST,
  GET_CATEGORY_FAILURE_REQUEST,
  GET_PRODUCT_BASIC_DATA_SUCCESS,
  GET_PRODUCT_BASIC_DATA_FAILURE,
  GET_LICENSED_SUBCODES_SUCCESS_REQUEST,
  GET_LICENSED_SUBCODES_FAILURE_REQUEST,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';

/* -----------------------------------get-targetList-----------------------------------*/

export function getTargetList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* targetMarketSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(getTargetList, '/get-target-markets', token);
    const targetList = response.data.targetMarkets;

    yield put({ type: GET_TARGET_MARKET_SUCCESS_REQUEST, targetList });
  } catch (error) {
    yield put({ type: GET_TARGET_MARKET_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------get-subcodes--------------------------------------*/

export function getSubcodesList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getSubcodesSaga(action) {
  try {
    const queryString = (action.data.serviceName) ? `?serviceName=${action.data.serviceName}` : '';
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getSubcodesList, `/get-subcodes${queryString}`, token);
    const sub_codeList = response.data.subcodes;

    yield put({ type: GET_SUBCODES_SUCCESS_REQUEST, sub_codeList });
  } catch (error) {
    yield put({ type: GET_SUBCODES_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------get-licensed-subcodes--------------------------------------*/

export function getLicensedSubcodesList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getLicensedSubcodesSaga(action) {
  try {
    const queryString = (action.data.serviceName) ? `?serviceName=${action.data.serviceName}` : '';
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getLicensedSubcodesList, `/get-licensed-subcodes${queryString}`, token);
    const licensed_sub_codeList = response.data;
    yield put({ type: GET_LICENSED_SUBCODES_SUCCESS_REQUEST, licensed_sub_codeList });
  } catch (error) {
    yield put({ type: GET_LICENSED_SUBCODES_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------get-brandList--------------------------------------*/

export function getBrandList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getBrandListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(getBrandList, `/get-brands?${action.data.queryParams}`, token);
    const brand_list = response.data.brands;
    yield put({ type: GET_BRANDS_SUCCESS_REQUEST, brand_list });
  } catch (error) {
    yield put({ type: GET_BRANDS_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------get-Category--------------------------------------*/

export function getCategoryList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCategoryListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getCategoryList, '/get-categories', token);
    const categoryData = response.data.data;
    yield put({ type: GET_CATEGORY_SUCCESS_REQUEST, categoryData });
  } catch (error) {
    yield put({ type: GET_CATEGORY_FAILURE_REQUEST, error });
  }
}

export function getProductBasicData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getProductBasicDataSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(getProductBasicData, `/sde/get-product-basic-data?${action.data.params}`, token);
    const basicData = response.data.data;
    yield put({ type: GET_PRODUCT_BASIC_DATA_SUCCESS, basicData });
  } catch (error) {
    yield put({ type: GET_PRODUCT_BASIC_DATA_FAILURE, error });
  }
}
