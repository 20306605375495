import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_UNITS_SUCCESS_REQUEST,
  GET_UNITS_FAILURE_REQUEST,
  GET_QUALIFIERS_SUCCESS_REQUEST,
  GET_QUALIFIERS_FAILURE_REQUEST,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';

/* -----------------------------------units-request-----------------------------------*/
export function handleGetUnits(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* handleGetUnitsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(handleGetUnits, '/structured-nutrition/units', token);
    const units = response.data.data;
    yield put({ type: GET_UNITS_SUCCESS_REQUEST, units });
  } catch (error) {
    yield put({ type: GET_UNITS_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------qualifiers-request-----------------------------------*/
export function handleGetQualifiers(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* handleQualifiersSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(handleGetQualifiers, '/structured-nutrition/qualifiers', token);
    const qualifiers = response.data.data;
    yield put({ type: GET_QUALIFIERS_SUCCESS_REQUEST, qualifiers });
  } catch (error) {
    yield put({ type: GET_QUALIFIERS_FAILURE_REQUEST, error });
  }
}
