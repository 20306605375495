import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withAuth0 } from '@auth0/auth0-react';

import { CLEAR_PROGRESS_BAR } from '../../../redux/actions/Actions';

function UploadItem(props) {
  const {
    jobId, file, progress, type, status, errorMsg, imgValErrorMsg, typeLabel, gtin,
  } = props.file;
  const { t, internetLost } = props;

  let uploadMsg = '';
  const imgValidMessage = [];
  let statusClass = '';
  let loader = false;
  let progressstatus = 0;
  let loaderClass = '';
  let closeButton = false;
  const progressStatus = status;

  // to close progress bar card
  const handleCloseAssetUploadCard = (delJobId) => {
    const fileProgresData = props.fileProgress;
    const fileProgresObj = { ...fileProgresData };
    delete fileProgresObj[delJobId];
    props.handleUpdateProgressList({ ...fileProgresObj });
  };

  // image message
  if (type === 'image') {
    switch (progressStatus) {
      case 0: // in progress
        if (progress !== 100) {
          progressstatus = progress;
          uploadMsg = t('Uploading');
        } else {
          uploadMsg = t('Syncing');
          loader = true;
          statusClass = 'syncing';
          loaderClass = 'icon-spinner9 spinner mr-2';
        }
        break;

      case 1:
        uploadMsg = t('Uploading');
        break;

      case 2: // failed
        uploadMsg = t('Failed');
        statusClass = 'failed';
        loader = true;
        loaderClass = 'fa fa-times-circle mr-2';
        closeButton = true;
        break;

      case 3: // image validating
        uploadMsg = t('Validating');
        statusClass = 'syncing';
        loader = true;
        loaderClass = 'icon-spinner9 spinner mr-2';
        break;

      case 4: // image validation success
        uploadMsg = t('Uploaded');
        statusClass = 'success';
        loader = true;
        loaderClass = 'fa fa-check-circle mr-2';
        closeButton = true;
        break;

      case 5: // image validation failed
        uploadMsg = t('Validation failed');
        statusClass = 'failed';
        loader = true;
        loaderClass = 'fa fa-times-circle mr-2';
        closeButton = true;

        // image validation failed message
        if (imgValErrorMsg.data) {
          const x = imgValErrorMsg.data.data;
          const { length } = imgValErrorMsg.data.data;
          for (let i = 0; i < length; i++) {
            for (const anItem in x[i]) {
              if (anItem === 'targetMarket') {
                const tm = x[i][anItem] && x[i][anItem].map((v) => t(v));
                imgValidMessage.push(`${t('Target Market')} - ${tm}`);
              } else if (anItem === 'dpi') {
                const temp = x[i][anItem].s.join(` ${t('or')} `);
                imgValidMessage.push(t(x[i][anItem].msg).replace('%s', temp));
              } else {
                imgValidMessage.push(t(x[i][anItem].msg).replace('%s', x[i][anItem].s));
              }
            }
          }
        }

        break;

      default:
      // nothing to do
    }
  } else if (type === 'document') { // document message
    switch (progressStatus) {
      case 0: // in progress
        if (progress < 100) {
          progressstatus = progress;
          uploadMsg = t('Uploading');
          loaderClass = 'icon-spinner9 spinner mr-2';
        } else {
          uploadMsg = t('Syncing');
          loader = true;
          statusClass = 'syncing';
          loaderClass = 'icon-spinner9 spinner mr-2';
        }
        break;

      case 1: // success
        uploadMsg = t('Uploaded');
        statusClass = 'success';
        loaderClass = 'fa fa-check-circle mr-2';
        loader = true;
        closeButton = true;
        break;

      case 2: // failed
        uploadMsg = t('Failed');
        statusClass = 'failed';
        loaderClass = 'fa fa-times-circle mr-2';
        loader = true;
        closeButton = true;
        break;

      default:
      // nothing
    }
  }

  return (
    file ? (
      <div className="card p-2 border-bottom col uploaditem-card">
        <div className="card-header p-1 header-elements-inline border-bottom progressbar-header">
          <h2 className="card-title header-title truncate" title={`${typeLabel} ${gtin}`}>{`${typeLabel} ${gtin}`}</h2>
          <div className="header-elements colorCls">
            <div className="list-icons" title={`${typeLabel} ${gtin}`}>
              {closeButton || errorMsg === 'time_out' ? <button className="close" onClick={() => handleCloseAssetUploadCard(jobId)}><em className="icon-close2 ml-2 upload-card-close" /></button> : null}
            </div>
          </div>
        </div>
        <div className="card-body shadow-none mb-1 col uploaditem-body">
          <div className="wrapperItem">
            <div className="leftSide">
              <div className={progressstatus ? 'progressBar' : ''}>
                <div style={{ width: `${progressstatus}%` }} />
              </div>
              <div className="w-100">
                <label className="d-block">{file.name}</label>
                {internetLost && !closeButton ? null
                  : (
                    <div className={`w-50 badge badge-light badge-striped badge-striped-left border-left progress-notification${statusClass}`} id="spinner-dark-6">
                      {loader ? <i className={loaderClass} /> : ''}
                      {uploadMsg}
                    </div>
                  )}
              </div>
            </div>
            {progressstatus ? (
              <span className="percentage">
                {progressstatus}
                %
              </span>
            ) : <span />}
            {internetLost && !errorMsg && !closeButton ? <div className="errorMsg">{t('it seems there is no Internet connection at the moment. Please check your network settings. The upload will proceed automatically as soon as the issue is resolved.')}</div> : ''}
            {errorMsg ? <div className="errorMsg">{errorMsg === 'time_out' ? t('The file could not be uploaded. The network might be down or web server is unreachable. Please try again.') : t(errorMsg)}</div> : null}
            {imgValidMessage.length > 0 ? (
              <div className="errorMsg">
                {imgValidMessage.map((error, key) => (
                  <div key={key}>{error}</div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ) : null
  );
}

const mapStateToProps = (state) => ({
  fileProgress: state.fileProgress,
});

const mapDispatchToProps = (dispatch) => ({
  handleUpdateProgressList: (data) => dispatch({ type: 'UPDATE_ASSET_PROGRESS_BAR_LIST', data }),
  handleCloseFileProgress: (data) => dispatch({ type: CLEAR_PROGRESS_BAR, data }),
});

export default withTranslation()(withAuth0(connect(mapStateToProps, mapDispatchToProps)(UploadItem)));
