const getIdToken = async (props) => {
  const { getIdTokenClaims, getAccessTokenSilently } = props;
  try {
    const claims = await getIdTokenClaims();
    if (claims && claims.__raw
            && (typeof claims.__raw !== 'undefined')) {
      return claims.__raw;
    } else {
      await getAccessTokenSilently();
      const claims = await getIdTokenClaims();
      if (claims && claims.__raw
                && (typeof claims.__raw !== 'undefined')) {
        return claims.__raw;
      }
      window.location.reload();
    }
  } catch (e) {
    console.error(e);
    // window.location.reload()
  }
};

export default getIdToken;
