import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_SUBCODE_SUCCESS_REQUEST,
  GET_SUBCODE_FAILURE_REQUEST,
  GET_BASIC_TEMPLATE_DATA_SUCCESS,
  GET_BASIC_TEMPLATE_DATA_FAILURE,
} from '../../../actions/nbb';
import getIdToken from '../../../../Utils/getIdToken';
import { GET_SUBCODE_LIST, GET_BASIC_TEMPLATE_ATTRIBUTES } from '../../../../constant/Apis';

// ---------------------------------------handle-subscriptions-request-------------------------------------------
export function getSubcodeRequest(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getSubcodeSaga(action) {
  try {
    const queryString = (action.data.createProduct) ? `?createProduct=${action.data.createProduct}` : '';
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getSubcodeRequest, GET_SUBCODE_LIST + queryString, token);
    const { data } = response.data;
    yield put({ type: GET_SUBCODE_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_SUBCODE_FAILURE_REQUEST, error });
  }
}

// -------------------------------------handle-get-basic-temp-------------------------------------------------

export function handleGetBasicTempRequest(url, token, param) {
  return axiosApiCall.get(url, {
    params: {
      targetMarket: param,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },

  });
}
export function* getBasicTempSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetBasicTempRequest, GET_BASIC_TEMPLATE_ATTRIBUTES, token, action.data.tm);
    const { data } = response.data;
    yield put({ type: GET_BASIC_TEMPLATE_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_BASIC_TEMPLATE_DATA_FAILURE, error });
  }
}
