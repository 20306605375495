import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_PRODUCT_TOPUP_LAYOUTS_SUCCESS,
  GET_PRODUCT_TOPUP_LAYOUTS_FAILURE,
  GET_USER_UPLOADED_ASSET_SUCCESS,
  GET_USER_UPLOADED_ASSET_FAILURE,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';
import { GET_SAVED_LAYOUTS, GET_USER_UPLOADED_ASSETS } from '../../../../constant/Apis';

/* -----------------------------------get-Status--------------------------------------*/

export function getProductTopupLayout(url, token, productId) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
      productId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getProductTopupLayoutSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getProductTopupLayout, GET_SAVED_LAYOUTS, token, action.data.productId);
    const { data } = response.data;
    yield put({ type: GET_PRODUCT_TOPUP_LAYOUTS_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_PRODUCT_TOPUP_LAYOUTS_FAILURE, error });
  }
}

export function getUserUploadedAsset(url, token, productId) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
      productId,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getUserUploadedAssetSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getProductTopupLayout, GET_USER_UPLOADED_ASSETS, token, action.data.productId);
    const { data } = response.data;
    yield put({ type: GET_USER_UPLOADED_ASSET_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_USER_UPLOADED_ASSET_FAILURE, error });
  }
}
