import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import { isAllowedForSubCode, checkProductLicense } from '../../../../Utils/userPermissions';
import * as Constants from '../../../../constant/constant';
import {
  GET_CERTIFIED_PRODUCT_DATA_SUCCESS,
  GET_CERTIFIED_PRODUCT_DATA_FALIURE,
  GET_CERTIFIED_CATEGORY_SUCCESS_REQUEST,
  GET_CERTIFIED_CATEGORY_FAILURE_REQUEST,
  GET_CERTIFIED_STATUS_SUCCESS_REQUEST,
  GET_CERTIFIED_STATUS_FAILURE_REQUEST,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';

export function getCertifiedProductData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCertifiedProductSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(getCertifiedProductData, `/certified/get-product-data?pimcoreId=${action.data.id}`, token);
    if (action.data.handleSetIsDataChanged) {
      action.data.handleSetIsDataChanged(false);
    }
    const product = response.data;
    yield put({ type: GET_CERTIFIED_PRODUCT_DATA_SUCCESS, product });

    if (!checkProductLicense(product.productLicense, Constants.LICENSE_SERVICES.CORE) && ((action.data.props.match.url === `/certified/edit/${action.data.id}`))) {
      action.data.props.history.push(`/certified/details/${action.data.id}`);
    }

    if (product.lockedReason === Constants.PRODUCT_STATUS_TITLE.no_live_version && (product.isLocked || !product.status)) {
      action.data.props.history.push('/certified');
    }

    if ((product.status === Constants.PRODUCT_STATUS.AWAITING_REVIEW) && ((action.data.props.match.url === `/certified/details/${action.data.id}`) || (action.data.props.match.url === `/certified/edit/${action.data.id}`))) {
      action.data.props.history.push(`/certified/awaiting-review/${action.data.id}`);
    }

    if (((product.status !== Constants.PRODUCT_STATUS.AWAITING_APPROVAL) && (product.status !== Constants.PRODUCT_STATUS.AWAITING_CORE_DATA_APPROVAL) && (product.status !== Constants.PRODUCT_STATUS.OUTSTANDING_REJECTION) && (product.status !== Constants.PRODUCT_STATUS.AWAITING_PRE_APPROVAL)) && (action.data.props.match.url === `/certified/approval/${action.data.id}`)) {
      action.data.props.history.push('/certified');
    }

    if ((product.status === Constants.PRODUCT_STATUS.AWAITING_APPROVAL || product.status === Constants.PRODUCT_STATUS.AWAITING_CORE_DATA_APPROVAL || product.status === Constants.PRODUCT_STATUS.OUTSTANDING_REJECTION || product.status === Constants.PRODUCT_STATUS.AWAITING_PRE_APPROVAL) && ((action.data.props.match.url === `/certified/details/${action.data.id}`) || (action.data.props.match.url === `/certified/edit/${action.data.id}`))) {
      action.data.props.history.push('/certified');
    }

    if (action.data.props.disabled !== undefined) {
      const editview_permissions = action.data.props.userDetails.permission;
      if (editview_permissions && !action.data.props.disabled && (product.isLocked || (product.status == Constants.PRODUCT_STATUS.DELISTED || product.status == Constants.PRODUCT_STATUS.AWAITING_APPROVAL || product.status == Constants.PRODUCT_STATUS.AWAITING_CORE_DATA_APPROVAL || product.status == Constants.PRODUCT_STATUS.OUTSTANDING_REJECTION || product.status == Constants.PRODUCT_STATUS.AWAITING_PRE_APPROVAL)
                || (!(isAllowedForSubCode('edit', product.subcode, editview_permissions, Constants.SERVICES_NAMES.TOPUP) || isAllowedForSubCode('edit', product.subcode, editview_permissions, Constants.SERVICES_NAMES.RSC))
                    && !(isAllowedForSubCode('approve', product.subcode, editview_permissions, Constants.SERVICES_NAMES.TOPUP) || isAllowedForSubCode('approve', product.subcode, editview_permissions, Constants.SERVICES_NAMES.RSC)))
                || !(checkProductLicense(product.productLicense, Constants.LICENSE_SERVICES.TOPUP) || checkProductLicense(product.productLicense, Constants.LICENSE_SERVICES.RSC) || checkProductLicense(product.productLicense, Constants.LICENSE_SERVICES.TOPUPIMAGE)))) {
        action.data.props.history.push(`/certified/details/${action.data.id}`);
      }
    }
  } catch (error) {
    yield put({ type: GET_CERTIFIED_PRODUCT_DATA_FALIURE, error });
  }
}

/* -----------------------------------get-Filters-Category--------------------------------------*/

export function getDynamicCategoryList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCertifiedCategoryFilterListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getDynamicCategoryList, '/certified/get-dynamic-categories', token);
    const dynamicCategoryList = response.data.data;
    yield put({ type: GET_CERTIFIED_CATEGORY_SUCCESS_REQUEST, dynamicCategoryList });
  } catch (error) {
    yield put({ type: GET_CERTIFIED_CATEGORY_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------get-Status--------------------------------------*/

export function getDynamicFilterStatus(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCertifiedStatusFilterSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getDynamicFilterStatus, '/certified/get-dynamic-status', token);
    const dynamicStatusList = response.data.data;
    yield put({ type: GET_CERTIFIED_STATUS_SUCCESS_REQUEST, dynamicStatusList });
  } catch (error) {
    yield put({ type: GET_CERTIFIED_STATUS_FAILURE_REQUEST, error });
  }
}
