import { call, put } from 'redux-saga/effects';

import {
  GET_FILTER_DATA_SUCCESS,
  GET_FILTER_DATA_FAILURE,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import axiosApiCall from '../../../../constant/axiosApiCall';
import { GET_LIST_FILTER_DATA } from '../../../../constant/Apis';
import { getDcTime } from '../../../../Utils/utils';

export async function handleGetListFilterData(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getListFilterSaga(action) {
  try {
    let subcode = [];
    if (localStorage.getItem(action.data.localStorageKey)) {
      const filterRequest = JSON.parse(localStorage.getItem(action.data.localStorageKey));
      subcode = JSON.stringify([...filterRequest.filter.subcode]);
    }
    const dcTime = getDcTime();
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetListFilterData, `${GET_LIST_FILTER_DATA}?serviceName=${action.data.serviceName}&subcode=${subcode}&_dc=${dcTime}`, token);
    const listFilterData = response.data;
    yield put({ type: GET_FILTER_DATA_SUCCESS, listFilterData });
  } catch (error) {
    yield put({ type: GET_FILTER_DATA_FAILURE, error });
  }
}
