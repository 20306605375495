import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_SDE_RECENT_EDITED_PRODUCTS_SUCCESS,
  GET_SDE_RECENT_EDITED_PRODUCTS_FAILURE,
  GET_SDE_LATEST_PENDING_APPROVAL_SUCCESS,
  GET_SDE_LATEST_PENDING_APPROVAL_FAILURE,
  GET_COUNT_PRODUCT_SUCCESS_REQUEST,
  GET_COUNT_PRODUCT_FAILURE_REQUEST,
  GET_MARKETING_CONTENT_SUCCESS,
  GET_MARKETING_CONTENT_FAILURE,

  GET_COMPLETENESS_REPORT_SUCCESS_REQUEST,
  GET_COMPLETENESS_REPORT_FAILURE_REQUEST,
  HANDLE_GET_DASHBOARD_RSC_COUNT_FAILURE,
  HANDLE_GET_DASHBOARD_RSC_COUNT_SUCCESS,
  GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_SUCCESS,
  GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_FAILURE,
  GET_CERTIFIED_RECENT_EDITED_PRODUCTS_SUCCESS,
  GET_CERTIFIED_RECENT_EDITED_PRODUCTS_FAILURE,

} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { GET_PRODUCT_COUNT_BY_STATUS } from '../../../../constant/Apis';
import { getDcTime } from '../../../../Utils/utils';

/* -----------------------------------get-Count-Product----------------------------------*/

export async function getCountProduct(url, token, data) {
  const payload = {
    serviceName: data.serviceName,
    subcode: data.subcode,
  };
  return axiosApiCall.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCountProductSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(getCountProduct, GET_PRODUCT_COUNT_BY_STATUS, token, action.data);
    const countProduct = response.data.data;
    yield put({ type: GET_COUNT_PRODUCT_SUCCESS_REQUEST, countProduct });
  } catch (error) {
    yield put({ type: GET_COUNT_PRODUCT_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------RECENTLY-EDITED-PRODUCTS---------------------------------*/
export async function GetSdeRecentEditedProducts(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getSdeRecentEditedProductsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(GetSdeRecentEditedProducts, 'sde/get-recent-edited-products', {}, token);
    const recentlyEditedProduct = response.data.data;
    yield put({ type: GET_SDE_RECENT_EDITED_PRODUCTS_SUCCESS, recentlyEditedProduct });
  } catch (error) {
    yield put({ type: GET_SDE_RECENT_EDITED_PRODUCTS_FAILURE, error });
  }
}

/* -----------------------------------PENDING-APPROVAL-PRODUCTS---------------------------------*/
export async function GetSdeLatestPendingApprovalProducts(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getLatestSdePendingApprovalProductsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(GetSdeLatestPendingApprovalProducts, 'sde/get-sde-products', {}, token);
    const pendingApprovalProduct = response.data.data;
    yield put({ type: GET_SDE_LATEST_PENDING_APPROVAL_SUCCESS, pendingApprovalProduct });
  } catch (error) {
    yield put({ type: GET_SDE_LATEST_PENDING_APPROVAL_FAILURE, error });
  }
}

/* -----------------------------------Marketing Content---------------------------------*/
export async function GetMarketingContent(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getMarketingContentSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(GetMarketingContent, '/user-details/get-marketing-content', token);
    const marketingData = response.data.data;
    yield put({ type: GET_MARKETING_CONTENT_SUCCESS, marketingData });
  } catch (error) {
    yield put({ type: GET_MARKETING_CONTENT_FAILURE, error });
  }
}

/* -----------------------------------Completeness Report---------------------------------*/
export async function GetCompletenessReports(url, token, action) {
  const data = {
    subcode: action.data.subcode,
  };
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCompletenessReportSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(GetCompletenessReports, 'dashboard/get-completeness-score', token, action);
    const completenessData = response.data.data;
    yield put({ type: GET_COMPLETENESS_REPORT_SUCCESS_REQUEST, completenessData });
  } catch (error) {
    yield put({ type: GET_COMPLETENESS_REPORT_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------RSC Count---------------------------------*/

export async function getRSCCount(url, token, action) {
  const data = {
    subcode: action.data.subcode,
  };
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getRSCCountSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(getRSCCount, 'dashboard/get-top-rsc-products', token, action);
    const RscBarData = response.data.data;
    yield put({ type: HANDLE_GET_DASHBOARD_RSC_COUNT_SUCCESS, RscBarData });
  } catch (error) {
    yield put({ type: HANDLE_GET_DASHBOARD_RSC_COUNT_FAILURE, error });
  }
}

/* -----------------------------------RECENTLY-EDITED-CERTIFIED-PRODUCTS---------------------------------*/
export async function GetRecentEditedCertifiedProduct(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getRecentEditedCertifiedProductSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(GetRecentEditedCertifiedProduct, 'certified/get-recent-edited-products', {}, token);
    const recentEditedCertifiedProduct = response.data.data;
    yield put({ type: GET_CERTIFIED_RECENT_EDITED_PRODUCTS_SUCCESS, recentEditedCertifiedProduct });
  } catch (error) {
    yield put({ type: GET_CERTIFIED_RECENT_EDITED_PRODUCTS_FAILURE, error });
  }
}

/* -----------------------------------LATEST-PENDING-APPROVAL-CERTIFIED-PRODUCTS---------------------------------*/
export async function GetLatestPendingApprovalCertifiedProducts(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getLatestPendingApprovalCertifiedProductsSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(GetLatestPendingApprovalCertifiedProducts, 'certified/get-latest-pending-approval-products', {}, token);
    const latestCertifiedProduct = response.data.data;
    yield put({ type: GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_SUCCESS, latestCertifiedProduct });
  } catch (error) {
    yield put({ type: GET_CERTIFIED_LATEST_PENDING_APPROVAL_PRODUCTS_FAILURE, error });
  }
}
