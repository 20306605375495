import axios from 'axios';

import * as Constants from './constant';

const axiosApiCall = axios.create({
  baseURL: Constants.API_URL,
  timeout: 200000,
});

axiosApiCall.interceptors.response.use(undefined, (err) => {
  if (err.response && err.response.status === 403) {
    setTimeout(() => {
      handleCheckAuthorization.getError(err).then((res) => {
        throw res;
      });
    }, 0);
  } else if (err.response && err.response.status === 401 && err.response.statusText == 'Unauthorized') {
    setTimeout(() => {
      handleCheckAuthorization.getError(err).then((res) => {
        throw res;
      });
    }, 0);
  }
  throw err;
});

const handleCheckAuthorization = {
  getTokenPromise: null,
  getError(error) {
    if (!this.getTokenPromise) {
      this.getTokenPromise = new Promise((reject) => {
        setTimeout(() => {
          window.location.href = '/logout';
          reject(error);
        }, 2000);
      });
    }
    return this.getTokenPromise;
  },
};

axiosApiCall.defaults.headers.common['Content-Type'] = 'application/json';
// axiosApiCall.defaults.headers.common['Accept-Language'] = localStorage.getItem('language-code')
// axiosApiCall.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axiosApiCall.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
axiosApiCall.defaults.headers.common['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers';
axiosApiCall.defaults.headers.common['Time-Zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
axiosApiCall.defaults.params = {};
axiosApiCall.defaults.params.pimcore_admin = true;

export default axiosApiCall;
