import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { randomNumbers } from './Utils/utils';

function Auth0ProviderWithHistory({ children }) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  let connection = process.env.REACT_APP_AUTH0_CONNECTION;
  const history = useHistory();

  const query = new URLSearchParams(window.location.search);
  let connectionFromQuery = query.get('connection');
  if (connectionFromQuery) {
    connection = connectionFromQuery;
  } else {
    connectionFromQuery = '';
  }

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
    localStorage.setItem('sessionId', randomNumbers(20));
    localStorage.setItem('connection', connectionFromQuery);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin + window.location.search}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens
      connection={connection}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
