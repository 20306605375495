import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_HYBRID_COMPLETENESS_SCORE_SUCCESS_REQUEST,
  GET_HYBRID_COMPLETENESS_SCORE_FAILURE_REQUEST,
  GET_HYBRID_PRODUCT_COUNT_BY_SUCCESS_REQUEST_STATUS,
  GET_HYBRID_PRODUCT_COUNT_BY_FAILURE_REQUEST_STATUS,
  GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_SUCCESS_REQUEST,
  GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_FAILURE_REQUEST,
  GET_NBB_RECENT_EDITED_PRODUCTS_SUCCESS_REQUEST,
  GET_NBB_RECENT_EDITED_PRODUCTS_FAILURE_REQUEST,
  GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_SUCCESS_REQUEST,
  GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_FAILURE_REQUEST,
  GET_HYBRID_PRODUCT_VALIDATION_COUNT_SUCCESS_REQUEST,
  GET_HYBRID_PRODUCT_VALIDATION_COUNT_FAILURE_REQUEST,
  GET_DASHBOARD_RSC_PRODUCT_COUNT_SUCCESS_REQUEST,
  GET_DASHBOARD_RSC_PRODUCT_COUNT_FAILURE_REQUEST,
  GET_DASHBOARD_COUNT_BY_CERTIFICATION_SUCCESS_REQUEST,
  GET_DASHBOARD_COUNT_BY_CERTIFICATION_FAILURE_REQUEST,
} from '../../../actions/nbb';
import getIdToken from '../../../../Utils/getIdToken';
import {
  GET_HYBRID_COMPLETENESS_SCORE,
  GET_HYBRID_PRODUCT_COUNT_BY_STATUS,
  GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL,
  GET_RECENT_EDITED_NBB_PRODUCT,
  GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA,
  GET_HYBRID_PRODUCT_VALIDATION_COUNT,
  GET_DASHBOARD_RSC_PRODUCT_COUNT,
  GET_DASHBOARD_COUNT_BY_CERTIFICATION,
} from '../../../../constant/Apis';

/* -----------------------------------nbb-dashboard-Completeness Report---------------------------------*/
export async function GetHybridCompletenessReports(url, token, action) {
  const data = {
    subcode: action.data.subcode,
  };
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getHybridCompletenessReportSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(GetHybridCompletenessReports, GET_HYBRID_COMPLETENESS_SCORE, token, action);
    const completenessData = response.data.data;
    yield put({ type: GET_HYBRID_COMPLETENESS_SCORE_SUCCESS_REQUEST, completenessData });
  } catch (error) {
    yield put({ type: GET_HYBRID_COMPLETENESS_SCORE_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------get-nbb-dashboard-Product-count----------------------------------*/

export async function getHybridCountProduct(url, token, data) {
  const payload = {
    serviceName: data.serviceName,
    subcode: data.subcode,
  };
  return axiosApiCall.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getHybridCountProductSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(getHybridCountProduct, GET_HYBRID_PRODUCT_COUNT_BY_STATUS, token, action.data);
    const countProduct = response.data.data;
    yield put({ type: GET_HYBRID_PRODUCT_COUNT_BY_SUCCESS_REQUEST_STATUS, countProduct });
  } catch (error) {
    yield put({ type: GET_HYBRID_PRODUCT_COUNT_BY_FAILURE_REQUEST_STATUS, error });
  }
}

/* -----------------------------------get-nbb-dashboard-Product-count-channel---------------------------------*/

export async function getHybridProductChannelCount(url, token, data) {
  const payload = {
    serviceName: data.serviceName,
    subcode: data.subcode,
  };
  return axiosApiCall.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getHybridProductChannelCountSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(getHybridProductChannelCount, GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL, token, action.data);
    const countProductChannel = response.data.data;
    yield put({ type: GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_SUCCESS_REQUEST, countProductChannel });
  } catch (error) {
    yield put({ type: GET_HYBRID_PRODUCT_COUNT_BY_CHANNEL_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------RECENTLY-EDITED-NBB-PRODUCTS---------------------------------*/
export async function getRecentEditedNbbProduct(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getRecentEditedNbbProductSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getRecentEditedNbbProduct, GET_RECENT_EDITED_NBB_PRODUCT, action.data.payload, token);
    const recentEditedNbbProduct = response.data.data;
    yield put({ type: GET_NBB_RECENT_EDITED_PRODUCTS_SUCCESS_REQUEST, recentEditedNbbProduct });
  } catch (error) {
    yield put({ type: GET_NBB_RECENT_EDITED_PRODUCTS_FAILURE_REQUEST, error });
  }
}

/* -----------------------------------brandbank-captured-data---------------------------------*/
export async function getHybridBrandbankCapturedData(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getHybridBrandbankCapturedDataSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getHybridBrandbankCapturedData, GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA, action.data.payload, token);
    const brandbankCapturedData = response.data.data;
    yield put({ type: GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_SUCCESS_REQUEST, brandbankCapturedData });
  } catch (error) {
    yield put({ type: GET_HYBRID_AWAITING_BRANDBANK_CAPTURED_DATA_FAILURE_REQUEST, error });
  }
}

/* ------------------------------get-validation-product-count------------------------------------*/

export async function getHybridProductValidationCount(url, token, data) {
  const payload = {
    serviceName: data.serviceName,
    subcode: data.subcode,
  };
  return axiosApiCall.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getHybridProductValidationCountSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getHybridProductValidationCount, GET_HYBRID_PRODUCT_VALIDATION_COUNT, token, action.data);
    const countProductValidation = response.data.data;
    yield put({ type: GET_HYBRID_PRODUCT_VALIDATION_COUNT_SUCCESS_REQUEST, countProductValidation });
  } catch (error) {
    yield put({ type: GET_HYBRID_PRODUCT_VALIDATION_COUNT_FAILURE_REQUEST, error });
  }
}

/* ------------------------------rsc-nbb-product-count-----------------------------------------------------------------------------*/

export async function getRSCProductCount(url, token, action) {
  const data = {
    subcode: action.data.subcode,
  };
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getRSCProductCountSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);

    const response = yield call(getRSCProductCount, GET_DASHBOARD_RSC_PRODUCT_COUNT, token, action);
    const RscBarProductData = response.data.data;
    yield put({ type: GET_DASHBOARD_RSC_PRODUCT_COUNT_SUCCESS_REQUEST, RscBarProductData });
  } catch (error) {
    yield put({ type: GET_DASHBOARD_RSC_PRODUCT_COUNT_FAILURE_REQUEST, error });
  }
}

/* ------------------------------get-count-by-certification------------------------------------*/

export async function getCountByCertification(url, token, data) {
  const payload = {
    serviceName: data.serviceName,
    subcode: data.subcode,
  };
  return axiosApiCall.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCountByCertificationSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getCountByCertification, GET_DASHBOARD_COUNT_BY_CERTIFICATION, token, action.data);
    const countByCertification = response.data.data;
    yield put({ type: GET_DASHBOARD_COUNT_BY_CERTIFICATION_SUCCESS_REQUEST, countByCertification });
  } catch (error) {
    yield put({ type: GET_DASHBOARD_COUNT_BY_CERTIFICATION_FAILURE_REQUEST, error });
  }
}

export async function getNbbProductImageTypeData(url, token, products) {
  return axiosApiCall.post(url, products, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getNbbProductImageTypeListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const { productIds } = action.data;
    const response = yield call(getNbbProductImageTypeData, '/asset/nbb-product-image-type', token, { product: productIds });
    const { data } = response;
    yield put({ type: 'GET_NBB_PRODUCT_IMAGE_TYPE_SUCCESS', data });
  } catch (error) {
    yield put({ type: 'GET_NBB_PRODUCT_IMAGE_TYPE_FAILURE', error });
  }
}
