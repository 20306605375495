import { call, put } from 'redux-saga/effects';

import {
  GET_AWAITING_BRANDBANK_CAPTURED_LIST_SUCCESS,
  GET_AWAITING_BRANDBANK_CAPTURED_LIST_FALIURE,
  GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_SUCCESS,
  GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_FAILURE,
  GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_SUCCESS,
  GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_FAILURE,
} from '../../../actions/Actions';
import * as Constants from '../../../../constant/constant';
import getIdToken from '../../../../Utils/getIdToken';
import axiosApiCall from '../../../../constant/axiosApiCall';
// import { CERTIFIED_GET_TARGATE_USAGE_OPTION } from '../../../constant/Apis';
import { getDcTime } from '../../../../Utils/utils';
import { GET_CORE_PRODUCT_LIST, GET_CORE_PRODUCT_DATA, GET_CORE_DYNAMIC_CATEGORIES } from '../../../../constant/Apis';
// import { isAllowedForSubCode } from '../../../Utils/userPermissions';
// import { checkProductLicense } from '../../../Utils/userPermissions';

export async function handleAwaitingBrandbankCapturedList(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getAwaitingBrandbankCapturedListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(handleAwaitingBrandbankCapturedList, GET_CORE_PRODUCT_LIST, action.data.payload, token);
    const productList = response.data;
    yield put({ type: GET_AWAITING_BRANDBANK_CAPTURED_LIST_SUCCESS, productList });
  } catch (error) {
    yield put({ type: GET_AWAITING_BRANDBANK_CAPTURED_LIST_FALIURE, error });
  }
}

/* -----------------------------------approval-view-------------------------------------*/

export function getAwaitingApprovalViewProductData(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getAwaitingApprovalViewProductDataSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(getAwaitingApprovalViewProductData, `${GET_CORE_PRODUCT_DATA}?pimcoreId=${action.data.id}`, token);
    if (action.data.handleSetIsDataChanged) {
      action.data.handleSetIsDataChanged(false);
    }
    const product = response.data;

    yield put({ type: GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_SUCCESS, product });

    if (((product.status !== Constants.PRODUCT_STATUS.AWAITING_CORE_DATA_APPROVAL) && (product.status !== Constants.PRODUCT_STATUS.OUTSTANDING_REJECTION)) && (action.data.props.match.url === `/awaiting-brandBank-captured-data-review/approval/${action.data.id}`)) {
      action.data.props.history.push('/awaiting-brandBank-captured-data-review');
    }
  } catch (error) {
    yield put({ type: GET_AWAITING_APPROVAL_VIEW_PRODUCT_DATA_FAILURE, error });
  }
}

/* -----------------------------------get-Filters-Category--------------------------------------*/

export function getDynamicAwaitingBrandbankCapturedCategoryList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getAwaitingBrandbankCapturedFilterListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getDynamicAwaitingBrandbankCapturedCategoryList, GET_CORE_DYNAMIC_CATEGORIES, token);
    const dynamicCategoryList = response.data.data;
    yield put({ type: GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_SUCCESS, dynamicCategoryList });
  } catch (error) {
    yield put({ type: GET_AWAITING_BRANDBANK_CAPTURED_CATEGORY_FAILURE, error });
  }
}
