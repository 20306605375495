import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_LANGUAGE_LIST_SUCCESS,
  GET_LANGUAGE_LIST_FAILURE,
  GET_LANGUAGE_UPDATE_SUCCESS,
  GET_LANGUAGE_UPDATE_FAILURE,
  GET_USER_LANGUAGE_SUCCESS,
  GET_USER_LANGUAGE_FAILURE,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';

/* -----------------------------------get-Language-list-----------------------------------*/

export async function GetLanguageList(url, token) {
  return axiosApiCall.get(url, {
    params: {
      _dc: getDcTime(),
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getLanguagesListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(GetLanguageList, '/get-system-languages', token);
    const languageList = response.data;
    yield put({ type: GET_LANGUAGE_LIST_SUCCESS, languageList });
  } catch (error) {
    yield put({ type: GET_LANGUAGE_LIST_FAILURE, error });
  }
}

/* -----------------------------------get-update-language----------------------------------*/

export async function GetLanguageUpdate(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getUpdatedLanguageSaga(action) {
  const dataLangObj = {
    language: action.data.language,
    username: action.data.username,
  };
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(GetLanguageUpdate, '/update-user-language', dataLangObj, token);
    const updatedLanguage = response.data;
    localStorage.setItem('language-code', action.data.language);

    yield put({ type: GET_LANGUAGE_UPDATE_SUCCESS, updatedLanguage });
  } catch (error) {
    yield put({ type: GET_LANGUAGE_UPDATE_FAILURE, error });
  }
}

/* -----------------------------------get-User-Language-----------------------------------*/

export async function GetUserLanguage(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getUserLanguagesSaga(action) {
  const userData = {
    email: action.data.username,
  };

  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(GetUserLanguage, '/get-user-language', userData, token);
    const language = response.data;
    yield put({ type: GET_USER_LANGUAGE_SUCCESS, language });
  } catch (error) {
    yield put({ type: GET_USER_LANGUAGE_FAILURE, error });
  }
}
