import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_PRODUCT_SEARCH_SUCCESS_REQUEST,
  GET_PRODUCT_SEARCH_FAILURE_REQUEST,
  GET_ADVANCE_FILTER_DROPDOWN_SUCCESS_REQUEST,
  GET_ADVANCE_FILTER_DROPDOWN_FAILURE_REQUEST,
  GET_CIN_LOG_MSG_SUCCESS_REQUEST,
  GET_CIN_LOG_MSG_FAILURE_REQUEST,
  GET_CIC_LOG_MSG_SUCCESS_REQUEST,
  GET_CIC_LOG_MSG_FAILURE_REQUEST,
  GET_NBB_TARGETMARKET_LIST_SUCCESS,
  GET_NBB_TARGETMARKET_LIST_FAILURE,
  GET_PUBLISH_STATUS_LOG_SUCCESS_REQUEST,
  GET_PUBLISH_STATUS_LOG_FAILURE_REQUEST,
} from '../../../actions/nbb';
import getIdToken from '../../../../Utils/getIdToken';
import {
  GET_PRODUCT_SEARCH, GET_ADVANCE_FILTER_DROPDOWN, GET_CIN_LOG_DATA, GET_CIC_LOG_DATA, NBB_TARGET_MARKET_API, GET_PUBLISH_STATUS_LOG_API,
} from '../../../../constant/Apis';

// ---------------------------------------handle-list-product-request--------------------------------------------
export function handleGetProductSearchRequest(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* handleProductSearchSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.props.auth0);
    const response = yield call(handleGetProductSearchRequest, GET_PRODUCT_SEARCH, action.data.payload, token);
    const { data } = response.data;
    yield put({ type: GET_PRODUCT_SEARCH_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_PRODUCT_SEARCH_FAILURE_REQUEST, error });
  }
}

// ---------------------------------------handle-get-advance-filter-dropdown--------------------------------------------
export function handleGetAdvanceFilterDropdown(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getAdvanceFilterDropdownSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetAdvanceFilterDropdown, GET_ADVANCE_FILTER_DROPDOWN, token);
    const { data } = response.data;
    yield put({ type: GET_ADVANCE_FILTER_DROPDOWN_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_ADVANCE_FILTER_DROPDOWN_FAILURE_REQUEST, error });
  }
}

// -------------------------cin-log-message------------------------------------------------------------
export function handleGetCINLogMessage(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getCINLogMessageSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetCINLogMessage, GET_CIN_LOG_DATA, action.data.payload, token);
    const { data } = response.data;
    yield put({ type: GET_CIN_LOG_MSG_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_CIN_LOG_MSG_FAILURE_REQUEST, error });
  }
}

// -------------------------cic-log-message------------------------------------------------------------
export function handleGetCicLogMsgRequest(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* handleGetCicLogMsgSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetCicLogMsgRequest, GET_CIC_LOG_DATA, action.data.payload, token);
    const { data } = response.data;
    yield put({ type: GET_CIC_LOG_MSG_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_CIC_LOG_MSG_FAILURE_REQUEST, error });
  }
}

/* ------------GET NBB TM-------- */
export function handleGetNBBTargetMarket(url, token) {
  return axiosApiCall.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* handleGetNBBTargetMarketSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetNBBTargetMarket, NBB_TARGET_MARKET_API, token);
    const { data } = response.data;
    yield put({ type: GET_NBB_TARGETMARKET_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_NBB_TARGETMARKET_LIST_FAILURE, error });
  }
}

/* ----------------------------------Publish-status-log----------------------------------------------------*/
export function handleGetPublishStatusLogRequest(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* handleGetPublishStatusLogSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(handleGetPublishStatusLogRequest, GET_PUBLISH_STATUS_LOG_API, action.data.payload, token);
    const { data } = response.data;
    yield put({ type: GET_PUBLISH_STATUS_LOG_SUCCESS_REQUEST, data });
  } catch (error) {
    yield put({ type: GET_PUBLISH_STATUS_LOG_FAILURE_REQUEST, error });
  }
}
