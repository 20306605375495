import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_MERGE_DETAIL_REPORT_SUCCESS,
  GET_MERGE_DETAIL_REPORT_FAILURE,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { GET_MERGE_DETAIL_REPORTS } from '../../../../constant/Apis';

// ---------------------------------------handle-subscriptions-request-------------------------------------------
export function getMergeDetailReports(url, token, payload) {
  const formData = new FormData();
  formData.append('filter[fromDate]', payload?.fromDate ? payload.fromDate : '');
  formData.append('filter[toDate]', payload?.toDate ? payload.toDate : '');
  formData.append('page', payload?.page);
  formData.append('recordsPp', payload?.recordsPp);
  formData.append('sort', payload?.sort);
  formData.append('order', payload?.order);

  return axiosApiCall.post(url, formData, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
export function* getMergeDetailReportSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getMergeDetailReports, `${GET_MERGE_DETAIL_REPORTS}`, token, action.data.payload);
    const { data } = response;
    yield put({ type: GET_MERGE_DETAIL_REPORT_SUCCESS, data });
  } catch (error) {
    yield put({ type: GET_MERGE_DETAIL_REPORT_FAILURE, error });
  }
}
