import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';

import * as Constants from './constant/constant';

const sessionId = localStorage.getItem('sessionId');

i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
    lng: Constants.DEFAULT_LANGUAGE_CODE,
    // fallbackLng: Constants.DEFAULT_LANGUAGE_CODE,
    // preload: ['de', 'fr', 'nl', 'cs', 'pl', 'sk', 'hu'],
    ns: ['translation'],
    defaultNS: 'translation',
    returnEmptyString: false,
    saveMissing: false,
    nsSeparator: false,
    keySeparator: false,
    backend: {
      loadPath: '/locales/{{lng}}.json',
      addPath: `${process.env.REACT_APP_PIMCORE_API_URL}/locales/add/{{lng}}/{{ns}}`,
      parsePayload(namespace, key, fallbackValue) {
        return {
          key,
          path: window.location.href,
        };
      },
      customHeaders: {
        'Content-Type': 'application/json;charset=utf-8',
        'x-api-key': sessionId,
      },
    },
  });

i18n.on('languageChanged', (lng) => {
  localStorage.setItem('language-code', lng);
});

// i18n.on('missingKey', function (lng, namespace, key, res) {
//     //console.log(lng, namespace, key, res, window.location.href);
//     // if (key === '') {
//     //     return;
//     // }
//     // let formData = new FormData();
//     // formData.append('key', key);
//     // formData.append('path', window.location.href);
//     // let url = process.env.REACT_APP_PIMCORE_API_URL + '/locales/add/' + lng + '/' + namespace;
//     // //console.log(url);
//     // axiosApiCall.post(url, formData, {
//     //     headers: {
//     //         'Content-Type': 'application/json;charset=utf-8',
//     //         'Authorization': 'Bearer acds',
//     //         'x-api-key': sessionId
//     //     }
//     // }).then((response) => {
//     //     //console.log(response)
//     // }).catch((error) => {
//     //     console.log(error)
//     // });
// });

export default (i18n);
