import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import {
  GET_CH_PLUS_REPORT_SUCCESS_REQUEST,
  GET_CH_PLUS_REPORT_FAILURE_REQUEST,
} from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';
import { getDcTime } from '../../../../Utils/utils';

const isPoolingError = false;

export async function getHealthAndRanks(url, token, action) {
  const data = {
    subcode: action.data.subcode,
  };
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getContentHealthAndRankRquestSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(getHealthAndRanks, 'dashboard/get-chp-score-rank', token, action);
    const healthRankData = response.data.data;
    yield put({ type: GET_CH_PLUS_REPORT_SUCCESS_REQUEST, healthRankData });
  } catch (error) {
    yield put({ type: GET_CH_PLUS_REPORT_FAILURE_REQUEST, error });
  }
}

export async function getRecalculateScore(url, token, action) {
  const data = {
    pimcoreId: action.data.productId,
  };
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getProductRecalculateScore(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    yield call(getRecalculateScore, 'certified/recalculate-chp-scores', token, action);
  } catch (error) {
    console.log('Error------>', error);
  }
}
