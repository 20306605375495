import React from 'react';
import FreshChat from 'react-freshchat';

class LiveChatWidget extends React.Component {
  render() {
    const token = process.env.REACT_APP_FRESHCHAT_TOKEN_ID;
    const tags = [...process.env.REACT_APP_FRESHCHAT_TAGS.split(',')];
    const faqTags = {
      tags: [...process.env.REACT_APP_FRESHCHAT_FAQ_TAGS.split(',')],
      filterType: process.env.REACT_APP_FRESHCHAT_FAQ_FILTER_TYPE,
    };
    return token && (
    <div>
      <FreshChat
        onInit={(widget) => {
        }}
        token={token}
        host="https://wchat.eu.freshchat.com"
        tags={tags}
        faqTags={faqTags}
      />
    </div>
    );
  }
}

export default LiveChatWidget;
