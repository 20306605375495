export const FLAG_PATH = '/assets/images/lang/';
export const API_URL = `${process.env.REACT_APP_PIMCORE_API_URL}/supplier-portal/v1`;
export const NBB_PORTAL_URL = `${process.env.REACT_APP_PIMCORE_API_URL}/auth0/login`;
export const CONNECTION_KEY = 'https://auth.brandbank.com/connection';

export const LABEL_IMAGE_SHOT_TYPE_ID = 'T0';
export const DISPLAY_DATA_TYPES = ['Pack Data', 'Retailer Specific Content', 'Supplier Specific Attributes', 'Connect Max'];
export const RSC_TAB = 'Retailer Specific Content';

export const CP_PRODUCT_CHANNELS = ['Default'];

export const DASHBOARD_PRODUCT_STATUS_VALUES = {
  'In Progress': 'in_progress',
  Live: 'approved',
  'Approval Processing': 'approval_processing',
  'Top-up Rejected': 'rejected',
  'Awaiting Top-up Approval': 'awaiting_approval',
  Delisted: 'delisted',
  Error: 'error',
  'Awaiting Brandbank Captured Data Review': 'awaiting_core_data_approval',
  'Brandbank Captured Data Rejected': 'outstanding_rejection',
  'Action Required': 'awaiting_review',
  'Awaiting Pre-Approval': 'awaiting_pre_approval',
  'Delist Requested': 'delist_requested',
  'Pending Live': 'pending_live',
  Draft: 'draft',
  'Queued for Publishing': 'queued_for_publishing',
  Published: 'published',
  'Awaiting Withdrawal': 'awaiting_withdrawal',
  'Publishing Error': 'publishing_error',
  Discontinued: 'delisted',
  'Hierarchy Withdrawal': 'hierarchy_withdrawal',
  'Published and Rejected by Subscriber': 'published_with_cic_error',
};

export const EDIT_VIEW_STATUSES = ['publishing_error', 'draft', 'published', 'hierarchy_withdrawal', 'queued_for_publishing', 'awaiting_withdrawal', 'discontinued', 'published_with_cic_error', 'rejected'];

export const RSC_BASIC_FIELD = {
  datatype: 'data',
  fieldtype: 'textarea',
  mandatory: false,
  name: 'description',
  phpdocType: 'string',
  title: 'Product Name',
};

export const DISPLAY_PRODUCT_STATUS = {
  in_progress: 'In Progress',
  awaiting_approval: 'Awaiting Top-up Approval',
  approved: 'Live',
  approval_processing: 'Approval Processing',
  rejected: 'Top-up Rejected',
  delisted: 'Delisted',
  error: 'Error',
  awaiting_core_data_approval: 'Awaiting Brandbank Captured Data Review',
  outstanding_rejection: 'Brandbank Captured Data Rejected',
  awaiting_review: 'Action Required',
  awaiting_pre_approval: 'Awaiting Pre-Approval',
  delist_requested: 'Delist Requested',
  pending_live: 'Pending Live',
  published_with_cic_error: 'Published and Rejected by Subscriber',
};

export const UNCERTIFIED_FILTER_STATUS = {
  in_progress: 'In progress',
  awaiting_approval: 'Awaiting Approval',
  approved: 'Live',
  rejected: 'Rejected',
  delisted: 'Delisted',
  error: 'Error',
};

export const PRODUCT_STATUS_TOOLTIP = {
  in_progress: 'Products that are in the process of being edited but aren’t yet approved with topup.',
  delisted: 'Products that have been delisted. These products will no longer be available for distribution to retailers.',
  error: 'Products that have failed publication. These products are not currently being distributed to any retailers. The Brandbank support team has been notified and will work to investigate and resolve the error.',
  rejected: 'The product’s top-up data has been rejected. The top-up content can be amended and then pushed back to Awaiting Approval.',
  delist_requested: 'Products where the customer has requested a delist, awaiting action by Nielsen Brandbank',
  approved: 'All aspects of the product have been approved, including the core data captured by Nielsen Brandbank, and any top up data which has been added. This product is available to be transferred to retailers.',
  pending_live: 'Products that have a future live date selected upon approval',
  approval_processing: 'Products that are in the process of being synced back to Nielsen Brandbank',
  awaiting_approval: 'Top-up data is currently awaiting approval. Core data captured by Nielsen Brandbank has already been accepted.',
  awaiting_review: 'This status represents a product which has been revised by Nielsen Brandbank, and the Brandbank captured data has been approved, but the copied over top up data has clashed with the Brandbank captured data. The clashes between the Brandbank data, and the top-up data needs to be reviewed.',
  awaiting_pre_approval: 'This status represents a product which has been revised by Nielsen Brandbank, and the Brandbank captured data has been approved, but the copied over top up data now needs to be approved.',
  outstanding_rejection: 'Product data/images which Nielsen Brandbank have captured has been rejected. The product will be amended by Nielsen Brandbank and then be pushed back to the Awaiting Brandbank Captured Data Review status.',
  awaiting_core_data_approval: 'Data which has been captured by Nielsen Brandbank is awaiting review. The product is not currently editable, top up data cannot be added until the Brandbank data has been accepted.',
};

export const NBB_PRODUCT_STATUS_TOOLTIP = {
  draft: 'Products that are in the process of being edited but aren’t yet approved.',
  queued_for_publishing: 'Products that have been approved and currently in the process of being sent to the relevant channel(s).',
  rejected: 'The product’s top-up data has been rejected. The top-up content can be amended and then pushed back to Awaiting Approval.',
  publishing_error: 'The product has received a notification that the data is not valid. Please check the Publish Status Log within the product for more information.',
  awaiting_core_data_approval: 'Data which has been captured by Nielsen Brandbank is awaiting review. The product is not currently editable, top up data cannot be added until the Brandbank data has been accepted.',
  outstanding_rejection: 'Product data/images which Nielsen Brandbank have captured has been rejected. The product will be amended by Nielsen Brandbank and then be pushed back to the Awaiting Brandbank Captured Data Review status.',
  published: 'All aspects of the product have been approved, including the core data captured by NielsenIQ Brandbank, and any data that has been added. This product is available to be transferred to the selected channels.',
  awaiting_approval: 'Top-up data is currently awaiting approval. Core data captured by Nielsen Brandbank has already been accepted.',
  awaiting_review: 'There is new Nielsen Brandbank data available for this product and there was previous data captured. Please review to prevent publishing delays.',
  awaiting_withdrawal: 'The product is unpublished and awaiting withdrawal from the relevant channel recipient(s).',
  delisted: 'The product has been discontinued and the data is no longer maintained.',
  published_with_cic_error: 'The recipient of this content has provided feedback for you to review, action and provide an amended version of the product. Please see the CIC log for more details.',
  pending_live: 'Products that have a future live date selected upon approval',
};

export const UNCERTIFIED_PRODUCT_STATUS_TOOLTIP = {
  approved: 'Products that have been completed and approved; these products are ready to be transferred to the relevant retailers.',
  in_progress: 'Products that are in the process of being completed but aren’t yet approved. Once all mandatory fields have been completed then they can be approved.',
  delisted: 'Products that have been delisted. These products will no longer be available for distribution to retailers. These products can be relisted again in the future if necessary.',
  error: 'Products that have failed publication. These products are not currently being distributed to any retailers. The Brandbank support team has been notified and will work to investigate and resolve the error.',

};

export const PRODUCT_STATUS_TITLE = {
  UNCERTIFIED_PRODUCT: 'The product is currently locked as the publication is being processed. Once the publication is complete the product will be available for editing.',
  deprecated_attributes: 'The product is locked. Please contact account manager to resolve the issue.',
  awaiting_core_data_approval: 'The product is locked. Awaiting for core data approval.',
  outstanding_rejection: 'The product is locked. Product core content is rejected.',
  delist_requested: 'The product is locked. Product is sent for delist',
  no_live_version: 'no_live_version',
  locked_due_to_cron_processing: 'This product is locked. Please wait for further action.',
  delisted: 'The product is locked. Product has been delisted',
  waiting_bridge_response: 'This product is locked. Please wait for further action.',
  bridge_queue_failed: 'The product data has been auto rejected due to data issue. Please contact your account manager to resolve the issue.',
};

export const PRODUCT_TYPES = {
  UNCERTIFIED_PRODUCT: 'uncertified-product',
  CERTIFIED_PRODUCT: 'certified-product',
  NBB: 'nbb',
  // AWAITING_BRANDBANK_CAPTURED_DATA_REVIEW: "awaiting-brandbank-captured-data-review"
};

export const PRODUCT_STATUS = {
  IN_PROGRESS: 'in_progress',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  APPROVAL_PROCESSING: 'approval_processing',
  AWAITING_APPROVAL: 'awaiting_approval',
  DELISTED: 'delisted',
  ERROR: 'error',
  AWAITING_CORE_DATA_APPROVAL: 'awaiting_core_data_approval',
  OUTSTANDING_REJECTION: 'outstanding_rejection',
  AWAITING_REVIEW: 'awaiting_review',
  AWAITING_PRE_APPROVAL: 'awaiting_pre_approval',
  DELIST_REQUEST: 'delist_requested',
  PENDING_LIVE: 'pending_live',
  PUBLISHED_WITH_CIC_ERROR: 'published_with_cic_error',
  AWAITING_WITHDRAWAL: 'awaiting_withdrawal',
};

export const WORKFLOW_TRANSITION_NAMES = {
  SEND_FOR_APPROVAL: 'send_for_approval',
  DIRECT_APPROVE_PRODUCT: 'direct_approve_product',
  DIRECT_TOP_UP_APPROVE_PRODUCT: 'direct_top_up_approve',
  APPROVE_PRODUCT: 'approve_product',
  REDIT_PRODUCT: 'redit_product',
  REJECT_PRODUCT: 'reject_product',
  EDIT_PRODUCT: 'edit_product',
  SEND_FOR_PROGRESS: 'send_for_in_progress',
};

export const ACTIVITY_LOG_MODAL = {
  ACTIVITY_LOG_DOWNLOAD_MODAL: {
    description: {
      message1: 'Following GTINs were requested to be enriched for GDSN, but are not present in the data pool extract. Upload to data pool of these GTINs will completely overwrite any existing product data within the data pool.',
      message2: 'To exclude the below GTIN\'s please press cancel & select only the GTIN\'s you require that are in your DAS extract, or manually remove the below GTINs from the downloaded extract prior to uploading into GS1 Data Source (DAS).',
    },
  },
};

export const WORKFLOW_TRANSITION = {
  SEND_FOR_APPROVAL: {
    title: 'Send for Approval',
    text: 'Are you sure you want to send this product for approval?',
    description: {
      message1: 'By selecting Save & Send for Approval you are saving any changes that you have made to the content. This content will now be sent to another user for final approval.',
      message2: 'Please note, this action does not distribute this content until final approval and publication is completed.',
    },
  },
  SUBMIT_FOR_APPROVAL: {
    title: 'Submit for Approval',
    text: 'Are you sure you want to submit this product for approval?',
    description: {
      message1: 'By selecting Submit for Approval you are saving any changes that you have made to the content. This content will now be sent to another user for final approval.',
      message2: 'Please note, this action does not distribute this content until final approval and publication is completed.',
    },
  },

  ABCD_VIEW_BULK_APPROVE: {
    title: 'Product Approval',
    description: {
      message1: 'Please confirm that you wish to approve the selected product (or the multiple products selected, where you are bulk approving content) and that you have the necessary authority to do so.',
      list_message: 'By clicking "Approve & Publish", you warrant (and take full responsibility for ensuring) that:',
      descList: [
        'you have the appropriate authority to approve the product content for distribution to data consumers and for display on consumer-facing media;',
        'any images and/or data (whether such data is present on the product packaging or is provided as supplementary information) are representative of the relevant product;',
        'all of the product content is suitable for external distribution to retailers and other data consumers (excluding any content designated as ‘supplier specific attributes’ within the relevant product record);',
        'any content specified as being ‘supplier specific attributes’ within the product record have been correctly designated as such. You hereby acknowledge and agree that any product information submitted by you in relation to the relevant products (e.g. supplementary information) will take precedence over any information coded by NielsenIQ Brandbank where the relevant data fields overlap;',
        'all images and data comply with all applicable product, food, labelling and information laws and regulations in force at the time of approval that are relevant to the market in which the product(s) are intended to be sold;',
        'in respect of food products intended to be offered for sale in England, all data relating to the HFSS (high in fat, salt and sugar) status and Nutrient Profile Score is accurate and current (particularly with regard to any revised / reformulated food products);',
        'you have obtained all necessary licences and consents (including from any relevant medical body) required for the marketing of each product;',
        'the product content is appropriate and applicable for each relevant market in which the product(s) are intended to be sold (including instances of dual labelling for UK and EU markets);',
        'you acknowledge that the images and/or data will be used by a variety of consumers;',
        'where you have the functionality to set a product ‘live date’, you acknowledge that it is your responsibility to ensure that live dates are set correctly and you acknowledge that the approved product information will be made publicly available from the live date specified;',
        'where you do not have the functionality to set a product ‘live date’, you acknowledge that the approved product information will be made publicly available from the date of your approval; and',
        'any supplementary information or amendments to a previously approved product do not change that product’s EU 1169 compliance status (as previously determined by your organisation),',
      ],
      message2: 'and you agree, on behalf of your organisation, to indemnify Nielsen BrandbankIQ and its group companies for all claims, costs, expenses, damages and losses (whether direct or indirect and whether anticipated or otherwise) suffered or incurred by Nielsen BrandbankIQ arising out of or in connection with a breach of the above warranties. This applies in addition to any other agreement in force between Nielsen BrandbankIQ and your organisation.',
    },
  },

  APPROVE_PRODUCT: {
    title: 'Product Approval',
    description: {
      message1: 'Please confirm that you wish to approve the selected product (or the multiple products selected, where you are bulk approving content) and that you have the necessary authority to do so.',
      list_message: 'By clicking "Approve", you warrant (and take full responsibility for ensuring) that',
      descList: [
        'you have the appropriate authority to approve this product for distribution to data consumers and for display on consumer-facing media;',
        'any images and/or data (whether such data is present on the product packaging or is provided as supplementary information) are representative of the relevant product;',
        'all of the product content is suitable for external distribution to retailers and other data consumers (excluding any content designated as ‘supplier specific attributes’ within the relevant product record);',
        'any content specified as being ‘supplier specific attributes’ within the product record have been correctly designated as such. You hereby acknowledge and agree that any product information submitted by you in relation to the relevant products (e.g. supplementary information) will take precedence over any information coded by NielsenIQ Brandbank where the relevant data fields overlap;',
        'all images and data comply with all applicable product, food, labelling and information laws and regulations in force at the time of approval that are relevant to the market in which the product(s) are intended to be sold;',
        'in respect of food products intended to be offered for sale in England, all data relating to the HFSS (high in fat, salt and sugar) status and Nutrient Profile Score is accurate and current (particularly with regard to any revised / reformulated food products);',
        'you have obtained all necessary licences and consents (including from any relevant medical body) required for the marketing of each product;',
        'the product content is appropriate and applicable for each relevant market in which the product(s) are intended to be sold (including instances of dual labelling for UK and EU markets);',
        'you acknowledge that the images and/or data will be used by a variety of consumers;',
        'where you have the functionality to set a product ‘live date’, you acknowledge that it is your responsibility to ensure that live dates are set correctly and you acknowledge that the approved product information will be made publicly available from the live date specified;',
        'where you do not have the functionality to set a product ‘live date’, you acknowledge that the approved product information will be made publicly available from the date of your approval; and',
        'any supplementary information or amendments to a previously approved product do not change that product’s EU 1169 compliance status (as previously determined by your organisation),',
      ],
      message2: 'and you agree, on behalf of your organisation, to indemnify Nielsen BrandbankIQ and its group companies for all claims, costs, expenses, damages and losses (whether direct or indirect and whether anticipated or otherwise) suffered or incurred by Nielsen BrandbankIQ arising out of or in connection with a breach of the above warranties. This applies in addition to any other agreement in force between Nielsen BrandbankIQ and your organisation.',
    },
  },

  NBB_APPROVE_PRODUCT_MSG: {
    title: 'Product Approval',
    description: {
      message1: 'Please confirm that you wish to approve the selected product (or the multiple products selected, where you are bulk approving content) and that you have the necessary authority to do so.',
      list_message: 'By clicking "Approve and Publish", you warrant (and take full responsibility for ensuring) that',
      descList: [
        'you have the appropriate authority to approve the product content for distribution to data consumers and for display on consumer-facing media;',
        'any images and/or data (whether such data is present on the product packaging or is provided as supplementary information) are representative of the relevant product;',
        'all of the product content is suitable for external distribution to retailers and other data consumers (excluding any content designated as ‘supplier specific attributes’ within the relevant product record);',
        'any content specified as being ‘supplier specific attributes’ within the product record have been correctly designated as such. You hereby acknowledge and agree that any product information submitted by you in relation to the relevant products (e.g. supplementary information) will take precedence over any information coded by NielsenIQ Brandbank where the relevant data fields overlap;',
        'all images and data comply with all applicable product, food, labelling and information laws and regulations in force at the time of approval that are relevant to the market in which the product(s) are intended to be sold;',
        'in respect of food products intended to be offered for sale in England, all data relating to the HFSS (high in fat, salt and sugar) status and Nutrient Profile Score is accurate and current (particularly with regard to any revised / reformulated food products);',
        'you have obtained all necessary licences and consents (including from any relevant medical body) required for the marketing of each product;',
        'the product content is appropriate and applicable for each relevant market in which the product(s) are intended to be sold (including instances of dual labelling for UK and EU markets);',
        'you acknowledge that the images and/or data will be used by a variety of consumers;',
        'where you have the functionality to set a product ‘live date’, you acknowledge that it is your responsibility to ensure that live dates are set correctly and you acknowledge that the approved product information will be made publicly available from the live date specified;',
        'where you do not have the functionality to set a product ‘live date’, you acknowledge that the approved product information will be made publicly available from the date of your approval; and',
        'any supplementary information or amendments to a previously approved product do not change that product’s EU 1169 compliance status (as previously determined by your organisation),',
      ],
      message2: 'and you agree, on behalf of your organisation, to indemnify NielsenIQ Brandbank and its group companies for all claims, costs, expenses, damages and losses (whether direct or indirect and whether anticipated or otherwise) suffered or incurred by NielsenIQ Brandbank arising out of or in connection with a breach of the above warranties. This applies in addition to any other agreement in force between NielsenIQ Brandbank and your organisation.',
    },
  },

  APPROVE_ABCD_VIEW_CORE_TOPUP: {
    title: 'Brandbank Captured Data Review',
    description: {
      descList: [],
      message1: 'Please confirm that you wish to approve the images and/or data for the selected product (or the multiple products selected, where you are bulk accepting content) and that you have the necessary authority to do so.',
      message2: 'PLEASE NOTE: THE PRODUCT CONTENT WILL NOT BE DISTRIBUTED TO THE RECIPIENT(S) AT THIS STAGE. By clicking the "Approve & Continue" button you will have the opportunity to review your product content completeness and retailer specific content and publish your product content to the intended recipient(s).',
      message3: 'If you are approving a revised product, please note that reformulated products may affect your HFSS status and you are solely responsible for keeping your Nutrient Profile Scores up to date.',
    },
  },

  NBB_VIEW_BULK_APPROVE_MSG: {
    title: 'Bulk Approve',
    description: {
      message1: 'Please confirm that you wish to approve the selected product (or the multiple products selected, where you are bulk approving content) and that you have the necessary authority to do so.',
      list_message: 'By clicking "Approve and Publish", you warrant (and take full responsibility for ensuring) that',
      descList: [
        'you have the appropriate authority to approve the product content for distribution to data consumers and for display on consumer-facing media;',
        'any images and/or data (whether such data is present on the product packaging or is provided as supplementary information) are representative of the relevant product;',
        'all of the product content is suitable for external distribution to retailers and other data consumers (excluding any content designated as ‘supplier specific attributes’ within the relevant product record);',
        'any content specified as being ‘supplier specific attributes’ within the product record have been correctly designated as such. You hereby acknowledge and agree that any product information submitted by you in relation to the relevant products (e.g. supplementary information) will take precedence over any information coded by NielsenIQ Brandbank where the relevant data fields overlap;',
        'all images and data comply with all applicable product, food, labelling and information laws and regulations in force at the time of approval that are relevant to the market in which the product(s) are intended to be sold;',
        'in respect of food products intended to be offered for sale in England, all data relating to the HFSS (high in fat, salt and sugar) status and Nutrient Profile Score is accurate and current (particularly with regard to any revised / reformulated food products);',
        'you have obtained all necessary licences and consents (including from any relevant medical body) required for the marketing of each product;',
        'the product content is appropriate and applicable for each relevant market in which the product(s) are intended to be sold (including instances of dual labelling for UK and EU markets);',
        'you acknowledge that the images and/or data will be used by a variety of consumers;',
        'where you have the functionality to set a product ‘live date’, you acknowledge that it is your responsibility to ensure that live dates are set correctly and you acknowledge that the approved product information will be made publicly available from the live date specified;',
        'where you do not have the functionality to set a product ‘live date’, you acknowledge that the approved product information will be made publicly available from the date of your approval; and',
        'any supplementary information or amendments to a previously approved product do not change that product’s EU 1169 compliance status (as previously determined by your organisation),',
      ],
      message2: 'and you agree, on behalf of your organisation, to indemnify NielsenIQ Brandbank and its group companies for all claims, costs, expenses, damages and losses (whether direct or indirect and whether anticipated or otherwise) suffered or incurred by NielsenIQ Brandbank arising out of or in connection with a breach of the above warranties. This applies in addition to any other agreement in force between NielsenIQ Brandbank and your organisation.',
    },
  },

  APPROVE_COREDATA_WITH_CORE_TOPUP: (accept) => ({
    title: 'Brandbank Captured Data Review',
    description: {
      descList: [],
      message1: 'Please confirm that you wish to accept the Brandbank Captured Data (or the multiple products selected, where you are bulk accepting content) and that you have the necessary authority to do so.',
      message2: `Please note THE PRODUCT WILL NOT BE DISTRIBUTED TO THE RECIPIENT AT THIS STAGE. By clicking the "${accept}" button you will have the opportunity to review your content completeness and retailer specific content and publish your product to the intended recipient(s).`,
      message3: 'You are approving a revised product. Please note that reformulated products may affect your HFSS status. Keep your Nutrient Profile Scores up to date.',
    },
  }),

  APPROVE_UNCERTIFIED_PRODUCT: {
    title: 'Product Approval',
    description: {
      message1: 'Please confirm that you wish to approve the selected product (or the multiple products selected, where you are bulk approving content) and that you have the necessary authority to do so.',
      list_message: 'By clicking "Approve", you warrant that',
      descList: [
        'you have the appropriate authority to approve this product for distribution to data consumers and for display on consumer-facing media;',
        'any images and/or data displayed are representative of the relevant product;',
        'all images and data comply with the applicable product and labelling laws and regulations in force at the time of approval that are relevant to the market in which the product(s) are to be sold;',
        'you have obtained all necessary licences and consents (including from any relevant medical body) required for the marketing of each product;',
        'you acknowledge that the images and/or data will be used by a variety of consumers;',
        'you acknowledge that it is your responsibility to ensure that “live dates” are set correctly and you acknowledge that the product information will be publicly available from the live date specified,',
      ],
      message2: 'and you agree, on behalf of your organisation, to indemnify Nielsen Brandbank and its group companies for all claims, costs, expenses, damages and losses (whether direct or indirect and whether anticipated or otherwise) suffered or incurred by Nielsen Brandbank arising out of or in connection with a breach of the above warranties. This applies in addition to any other agreement in force between Nielsen Brandbank and your organisation.',
    },
  },

  REJECT_PRODUCT: {
    title: 'Reject Product',
    text: 'Are you sure you want to reject this product?',
  },
};

export const ABCD_VIEW_APPROVE_MSG = 'In relation to the Food Information to Consumers Regulations (Retained Regulation (EU) No. 1169/2011), you warrant that the selected digital product content:';

export const ABCD_VIEW_BULK_APPROVE_MSG = 'The below conformation is applicable to the selected NielsenIQ Brandbank captured product images and/or data only:';

export const PRODUCT_LIST_SELECTION_LIMIT = {
  RELISTED: 20,
  DELISTED: 100,
  DOWNLOAD_ASSETS_ZIP: 10,
  EXPORT_PRODUCTS: 10,
  BULK_APPROVAL: 50,
  MAX_IMPORT_EXPORT_LIMIT: 400,
  BULK_REJECT: 50,
  PUBLISH_TO_GDSN: 100,
  DELETE: 30,
  NBB_BULK_APPROVAL: 100,
  CUSTOM_EXPORT_PRODUCT: 20,
};
export const DELIST_LIMIT = 21;

export const IMAGE_REQUIRED_TYPES = [
  'ID Shot',
];

export const IDENTITY_IMAGE_TYPE = 'ID Shot';
export const LABEL_IMAGE_SHOT_NAME = 'Label Imaging Shot';

export const REQUIRED_IMAGE_SHOT_TYPES = ['ID Shot'];

export const NUMERIC_DATA_TYPES = ['amount', 'decimal', 'integer', 'measurement', 'nonNegativeInteger', 'temperatureMeasurement', 'timeMeasurement'];

export const IMAGE_SHOT_TYPES_LIST = {
  'ID Shot': 'Pack ID shot',
  'Label Imaging Shot': 'Label Image',
};

export const DEFAULT_IMAGE_THUMBNAIL = '/assets/images/placeholders/image-placeholder.gif';
export const DEFAULT_LANGUAGE_CODE = 'en_GB';
export const DEFAULT_LANGUAGE_NAME = 'English';
export const SOURCE = 'supplier';

export const ACTIVITY_LOG_TYPES = {
  bulk_assets_download: 'Bulk Asset Download',
  product_import: 'Product Import',
  product_export: 'Product Export',
  product_delist: 'Delist',
  publish_to_gdsn: 'Publish to GDSN',
  nbb_product_import: 'NBB Product Import',
  nbb_product_export: 'NBB Product Export',
  nbb_bulk_image_download: 'NBB Bulk Certified Image Download',
  nbb_bulk_approve: 'NBB Bulk Approve',
  custom_data_extract: 'Custom Data Extract',
  bulk_image_extract: 'Custom Image Extract',
  bulk_connect_max: 'Custom Image Extract(Connect Max)'
};

export const FILTER_PRODUCTS_TYPES = {
  certified: 'Certified',
  uncertified: 'Uncertified',
  nbbproduct: 'Multi-Channel',
};

export const SERVICE_TYPE = {
  CERTIFIED: 'certified',
  UNCERTIFIED: 'uncertified',
  NBB_PRODUCT: 'nbbproduct',
};

export const BULK_ASSET_DOWNLOAD_STATUS = {
  in_progress: 'In progress',
  failed: 'Failed',
  completed: 'Completed',
};

export const ACTIVITY_LOGS_SET = {
  ENDPOINT: '/asset/get-activity-logs',
  GET_LIST_ACTION: 'get-list',
  DOWNLOAD_ASSET: 'download-activity-log-assets',
  DOWNLOAD_BTN_SVG: '/assets/images/icons-svg/download.svg',
};

export const MAX_COMMENTS_LENGTH = 4000;

export const LONG_TEXT_DEFAULT_NUM_ROWS = 3;

export const AVAILABILITY_START_DATE_KEY = 'Availability Start Date';

export const NUTRITION_KEY_NAME = 'Nutrition';
export const STR_NUTRITION_KEY_NAME = [
  'Structured Nutrition EU',
  'Structured Nutrition US',
];

export const DESCRIPTION_FIELD_TOOLTIP = 'The main title of the product that would usually be displayed on the front of the packaging.';
export const GTIN_FIELD_TOOLTIP = 'The GTIN should always be 14 digits. For a variable measure GTIN this must comply with the target market rules; have the price, weight or pieces element zeroed, and the trailing check digit re-calculated or zeroed as per the individual Retailer requirement';
export const GTIN_NUMBER_FILTER_TOOLTIP = 'The GTIN should always be 14 digits. Multiple GTIN numbers can be searched by entering comma (,) or space separated or in a new line';
export const FOP_TOOLTIP_MESSAGE = 'The nutrition table most often found on the front of the pack in a \'tombstone\' or \'chevron\' format. Includes associated header and footer information.';
export const COOKING_GUIDELINES_TOOLTIP_MESSAGE = 'Instructions on how to cook the product, organised by cooking method. For instructions that reference ingredients outside of the product, see Recipes.';
export const SUBSCRIPTION_TOOLTIP = 'The subscription code determines some of the accessibility and delivery options of product content. To find out more, contact your Brandbank Account Manager.';
export const TARGET_MARKET_TOOLTIP = 'The country of sale for this product';
export const CATEGORY_TOOLTIP = 'The 3-level category that this product belongs. Select the closest match. This category will determine the attributes available for capture.';
export const AVAILABILITY_TOOLTIP = 'The date from which the product content should be made available to recipients (e.g. retailers)';
export const MULTIPART_TOOLTIP = 'Check box if the product (selling unit) contains multiple components with different nutritional tables (e.g. meal kits, variety packs).';
export const ATTRIBUTE_TYPE_TOOLTIP = 'Select the attribute type you want to include in your products from the dropdown list. The character limits for each text field are as follows; Long Text Box - 5000 characters. Short Text Box - 250 characters. Multiple Short Text Boxes - 1000 characters in each box.';
export const ATTRIBUTE_LOCATION_TOOLTIP = 'Select whether you would like your attribute to display within the Neutral area or Language Specific Area of your product.';
export const MANDATORY_TOOLTIP = 'Tick this box to make the attribute mandatory. This will mean the product cannot be Approved unless this field is completed.';
export const ATTRIBUTE_NAME_TOOLTIP = 'Enter a name for the attribute that is being created. Max 100 characters.';
export const TOOLTIP_NAME_TOOLTIP = 'This box you are reading is a tooltip. It\'s a short description to explain the field that is being completed which will appear when the user hovers over the Information icon.';
export const TOOLTIP_FIELD_GLN = 'The Global Location Number (GLN) that uniquely identifies the Brand Owner of the product.';
export const TOOLTIP_FIELD_DATAPOOL = 'Datapool';
export const TOOLTIP_FIELD_GPC = 'The code used to group products based on similar characteristics according to the GS1 Global Product Classification (GPC).';
export const TOOLTIP_FIELD_TRADE_ITEM_DESCRIPTOR = 'The code that describes the product\'s packaging level.';
export const TOOLTIP_FIELD_IS_TRADE_ITEM_A_BASE_UNIT = 'The indicator that specifies this product does not contain another intended sellable unit.';
export const TOOLTIP_FIELD_PUBLICATION_CHANNEL = 'Publication Channel';
export const TOOLTIP_PUBLICATION_TYPE = 'GDSN Publication Type';
export const TOOLTIP_ADD_RECIPIENTS = 'Add Recipients';
export const TOOLTIP_COMPONENT = 'Indicates a component of a trade item.';

export const DESCRIPTION_FIELD_CHAR_LIMIT = 100;

export const ID_SHOT_GUIDE = {
  BE: '/documents/upload-guide/ID-Image-Specifications-Benelux.pdf',
  NL: '/documents/upload-guide/ID-Image-Specifications-Benelux.pdf',
  LU: '/documents/upload-guide/ID-Image-Specifications-Benelux.pdf',
  GB: '/documents/upload-guide/ID-Image-Specifications-UK_IE_PL_SK_HU_CZ.pdf',
  IE: '/documents/upload-guide/ID-Image-Specifications-UK_IE_PL_SK_HU_CZ.pdf',
  PL: '/documents/upload-guide/ID-Image-Specifications-UK_IE_PL_SK_HU_CZ.pdf',
  CZ: '/documents/upload-guide/ID-Image-Specifications-UK_IE_PL_SK_HU_CZ.pdf',
  SK: '/documents/upload-guide/ID-Image-Specifications-UK_IE_PL_SK_HU_CZ.pdf',
  HU: '/documents/upload-guide/ID-Image-Specifications-UK_IE_PL_SK_HU_CZ.pdf',
  AE: '/documents/upload-guide/ID-Image-Specifications-UK_IE_PL_SK_HU_CZ.pdf',
  PH: '/documents/upload-guide/ID-Image-Specifications-UK_IE_PL_SK_HU_CZ.pdf',
};

export const LABEL_IMAGE_SHOT_GUIDE = {
  BE: '/documents/upload-guide/Label-Image-Specifications.pdf',
  NL: '/documents/upload-guide/Label-Image-Specifications.pdf',
  LU: '/documents/upload-guide/Label-Image-Specifications.pdf',
  GB: '/documents/upload-guide/Label-Image-Specifications.pdf',
  IE: '/documents/upload-guide/Label-Image-Specifications.pdf',
  PL: '/documents/upload-guide/Label-Image-Specifications.pdf',
  CZ: '/documents/upload-guide/Label-Image-Specifications.pdf',
  SK: '/documents/upload-guide/Label-Image-Specifications.pdf',
  HU: '/documents/upload-guide/Label-Image-Specifications.pdf',
  AE: '/documents/upload-guide/Label-Image-Specifications.pdf',
  PH: '/documents/upload-guide/Label-Image-Specifications.pdf',
};

export const SSA_GUIDE = {
  en_GB: '/documents/upload-guide/Supplier-Specific-Attributes-UK.pdf',
};

export const COMPLETENESS_PROGRESS_TEXT_COLOR = '#AAA';

export const SERVICES_NAMES = {
  CERTIFIED: 'certified',
  UNCERTIFIED: 'uncertified',
  RSA: 'rsa',
  RSC: 'rsc',
  SSA: 'ssa',
  TOPUP: 'topup',
  CORE: 'core',
  NBBCORE: 'nbbCore',
  NBB: 'nbb',
  B2B: 'b2b',
  NBBPRODUCT: 'nbbProduct',
};

export const LICENSE_SERVICES = {
  SDE: 'SDE',
  TOPUP: 'TOPUP',
  CORE: 'CORE',
  RSC: 'RSC',
  SSA: 'SSA',
  B2B: 'B2B',
  TOPUPIMAGE: 'TOPUPIMAGE',
};

export const NEUTRAL_TABS = {
  WMPC: 'Weights & Measurements - Pack & Case',
  NWMPC: 'NEW: Weights & Measurements - Pack & Case',
  WMU: 'Weights & Measurements - Unit',
  LB: 'Lamp & Bulb',
};

export const TABS_ORDER = {
  Identity: '10',
  Identification: '20',
  Assets: '30',
  Marketing: '40',
  Addresses: '50',
  'Net Content': '60',
  'Packaging Information': '70',
  Origin: '80',
  Usage: '90',
  'Safety Information & Age Limits': '100',
  Ingredients: '110',
  'Nutritional Information': '120',
  HFSS: '121',
  'Allergens & Lifestyle': '130',
  Alcohol: '140',
  Pet: '150',
  Baby: '160',
  'Weights & Measurements - Unit': '170',
  'Weights & Measurements - Pack & Case': '180',
  'NEW: Weights & Measurements - Pack & Case': '190',
  Electrical: '200',
  'Health and Beauty': '210',
};

export const NBB_PUBLICATION_CHANNELS = {
  nbb: 'Brandbank eCommerce Distribution',
  gdsn: 'GDSN',
};

export const NBB_CERTIFICATION_STATUS = {
  certifiedProduct: 'Certified',
  unCertifiedProduct: 'Uncertified',
};

export const NBB_PRODUCT_VALIDATION_NAME = {
  error: 'Error',
  warning: 'Warning',
  announcement: 'Announcement',
};

export const NBB_PRODUCT_VALIDATION_TOOLTIP = {
  error: 'These are products that have validation errors that must be actioned before the product can be published.',
  warning: 'These are products that have validation errors that we recommend you action before the product is published.',
  announcement: 'These are products that contain important information such as upcoming changes. e.g an attribute that may become an \'error\' in the next quarterly release.',
};

export const PRODUCT_THUMBNAIL_NAME = 'product-medium-thumb-800';

export const ATTRIBUTE_TYPE_OPTION = [
  { label: 'Short Text Box', value: 'Input' },
  { label: 'Long Text Box', value: 'Textarea' },
  { label: 'Single Select Dropdown List', value: 'Select' },
  { label: 'Multiple Select Dropdown List', value: 'Multiselect' },
  { label: 'Multiple Short Text Boxes', value: 'Table' },
  { label: 'Numeric Field (Single Line)', value: 'Numeric' },
];

export const LANGUAGE_OPTION = [
  { label: 'Neutral', value: 'Neutral' },
  { label: 'Language Specific', value: 'Language Specific' },
];

export const COMPLETENESS_OPERATORS_LIST = {
  '<': 'less than',
  '>': 'greater than',
  '=': 'equal to',
  range: 'range',
};
export const COMPLETENESS_OPERATORS = [
  { value: '<', label: 'Less than' },
  { value: '>', label: 'Greater than' },
  { value: '=', label: 'Equal to' },
  { value: 'range', label: 'Range' },
];
export const ARTWORK_ATTRIBUTE_NAME = 'artworkuniquereference';

export const EXCLUDE_TAB = [
  'Identity',
  'Assets',
  'Identification',
];

export const COUNTRY_LABEL = {
  en_GB: 'English',
  en: 'English',
  cs: 'čeština',
  sk: 'slovenčina',
  fr: 'Français',
  nl: 'Nederlands',
  pl: 'Polski',
  hu: 'Magyar',
  de: 'Deutsch',
  th: 'ภาษาไทย',
  ms: 'Bahasa Melayu',
  pt: 'Português',
  zh: '中文',
  tr: 'Türkçe',
  it: 'Italiano',
  lv: 'Latvian (LV)',
  da: 'Dansk',
  en_AU: 'Australian English',
  es: 'Español',
  en_CA: 'English (Canada)',
  fr_CA: 'Français (Canada)',
  ro: 'Română',
  en_US: 'English',
};
export const MONITOR_NOTIFICATION_TIMER = 1000 * 60; // 1 Minute
export const CHP_REQUEST_TIMER = 2000;

export const CAMPAIGN_PAGES = {
  UNCERTIFIED: 'uncertified',
  CERTIFIED: 'certified',
  DASHBOARD: 'dashboard',
  NEWLINE: 'nlf',
};

export const CAMPAIGN_PAGES_URL = {
  UNCERTIFIED: 'uncertified-product',
  CERTIFIED: 'certified-product',
  DASHBOARD: 'home',
  CREATE_UNCERTIFIED_PRODUCT: '',
  ACTIVITY_LOG: '',
  PROFILE: '',
  SSA: '',
  SSA_EDIT: '',
  // EDITSSA:'/supplier-specific-attribute'
  // NEWLINE: 'nlf'
};

export const LOCAL_STORAGE_KEYS = {
  UNCERTIFIED_FILTER: 'uncertified-filter',
  CERTIFIED_FILTER: 'certified-filter',
  MARKETING_BANNER: 'isMarketingVisible',
  AWAITING_BRANDBANK_FILTER: 'awaiting-brandbank-filter',
};

export const SERVICE_TYPE_LOCAL_STORAGE_KEY = 'serviceType';

export const PRODUCT_NAME_STATUS = {
  EDITABLE: 'editable',
  NON_EDITABLE: 'non-editable',
  NBB_PL: 'nbb-pl',
};
export const LABELS = {
  TRADE_ITEM_DESCRIPTOR: 'Product Hierarchy Level Code',
  BRAND_NAME: 'Brand Name',
  GTIN: 'GTIN',
  SUBSCRIPTION: 'Subscription',
  TARGET_MARKET: 'Target Market',
  DATA_POOL: 'Data Pool',
  GPC: 'GPC',
  BASE_UNIT: 'Is Trade Item a Base Unit?',
  GLN: 'Information Provider(GLN)',
  PUBLICATION_CHANNEL: 'Publication Channel',
  PUBLICATION_DATE: 'Publication Date',
  EFFECTIVE_DATE: 'Effective Date',
  PUBLICATION_TYPE: 'GDSN Publication Type',
  CREATED_DATE: 'Created Date',
  MODIFIED_DATE: 'Modified Date',
  DESCRIPTION: 'Functional Name',
  CATEGORY: 'Category',
  RECIPIENT_NAME: 'Recipient Name',
  MESSAGE: 'Message',
  REFERENCE_ID: 'Reference Id',
  PUBLICATION_STATUS: 'Publication Status',
  CREATED_DATE_TIME: 'Created Date and Time',
  MESSAGE_TYPE: 'Message Type',
};

export const DATAPOOL_OPTION = [
  { label: 'GS1 Data Source (DAS)', value: 'das' },
];
export const SSA_PERMISSION_NAME = 'SSA';

export const BULK_ACTION_WARNING = {
  approve: {
    title: 'Bulk Approve',
    msg: `Only "${DISPLAY_PRODUCT_STATUS.awaiting_approval}/${DISPLAY_PRODUCT_STATUS.awaiting_pre_approval}/${DISPLAY_PRODUCT_STATUS.awaiting_core_data_approval}" product(s) can be approved`,
    editor_msg: `Only "${DISPLAY_PRODUCT_STATUS.in_progress}/${DISPLAY_PRODUCT_STATUS.awaiting_approval}/${DISPLAY_PRODUCT_STATUS.awaiting_pre_approval}/${DISPLAY_PRODUCT_STATUS.awaiting_core_data_approval}/${DISPLAY_PRODUCT_STATUS.pending_live}/${DISPLAY_PRODUCT_STATUS.approval_processing}" product(s) can be approved`,
    coreProductMsg: `Only "${DISPLAY_PRODUCT_STATUS.awaiting_core_data_approval}" product(s) can be approved`,
    sdeProductMsg: `Only "${DISPLAY_PRODUCT_STATUS.in_progress}/${DISPLAY_PRODUCT_STATUS.error}/${DISPLAY_PRODUCT_STATUS.approved}" product(s) can be approved`,
  },
  delist: {
    title: 'Delist',
    msg: `Only "${DISPLAY_PRODUCT_STATUS.approved}" product(s) can be delisted`,
  },
  relist: {
    title: 'Relist',
    msg: 'Only delisted product(s) can be relisted',
  },
  reject: {
    title: 'Bulk Reject',
    msg: `Only "${DISPLAY_PRODUCT_STATUS.awaiting_approval}/${DISPLAY_PRODUCT_STATUS.awaiting_core_data_approval}" product(s) can be rejected`,
    coreRejectMsg: `Only "${DISPLAY_PRODUCT_STATUS.awaiting_core_data_approval}" product(s) can be rejected`,
  },
  publishToGDSN: {
    title: 'Publish to GDSN',
    msg: `In order for products to be published to GDSN, these requires an "${DISPLAY_PRODUCT_STATUS.approved}" product and a valid license. Products can only be selected from the same Target Market.`,
  },
  duplicateGDSNProduct: {
    title: 'Publish to GDSN',
    msg: 'Duplicate GTIN product selected. Please select unique GTIN products.',
  },
};

export const CERTIFIED_MULTIPART_ATTR_DISABLE = true;

export const TARGET_USAGE = [
  { value: 1, display: 'Web and Print' },
  { value: 2, display: 'Space Planning' },
  { value: 5, display: 'Data - no image' },
];

export const LABEL_BENELUX_TMS = {
  BE: 'Belgium',
  NL: 'Netherlands',
  LU: 'Luxembourg',
  BELU: 'Belgium, Luxembourg',
};

// --------------------------------------------nbb-constant---------------------------------------------
export const DATA_POOL_OPTIONS = [
  { label: 'GPE', value: 'GPE' },
  { label: 'GS1 DAS', value: 'DAS' },
  { label: 'GS1 My Product Manager', value: 'CDB' },
];

export const PUBLICATION_CHANNELS = {
  NBB_PL: 'nbb',
  GDSN: 'gdsn',
  NBB: 'nbb',
};

export const PUBLICATION_TYPES_LIST = {
  PUBLIC: 'public',
  PRIVATE: 'private',
};

export const PUBLICATION_TYPES = [
  { label: 'Private', value: 'private' },
  { label: 'Public', value: 'public' },
];

export const PUBLICATION_CHANNEL_LIST = [
  { label: 'Brandbank eCommerce Distribution', value: 'nbb' },
  { label: 'GDSN', value: 'gdsn' },
];

export const PUBLICATION_CHANNEL_NBB_LABEL = 'Brandbank eCommerce Distribution';
export const PUBLICATION_CHANNEL_GDSN_LABEL = 'GDSN Channel';
export const CERTIFIED_CHANNEL_BRANDBANK_LABEL = 'Brandbank Channel';

export const PRODUCT_VALIDATIONS = [
  { label: 'Error', value: 'error' },
  { label: 'Warning', value: 'warning' },
  { label: 'Announcement', value: 'announcement' },
];

export const CERTIFICATION_STATUS_LIST = [
  { label: 'Uncertified', value: 'unCertifiedProduct' },
  { label: 'Certified', value: 'certifiedProduct' },
];

export const USERLANE_PROPERTY_ID = 'dw9rk';

export const DISPLAY_NBB_PRODUCT_STATUS = {
  draft: 'Draft',
  discarded: 'Discarded',
  awaiting_approval: 'Awaiting Top-up Approval',
  rejected: 'Top-up Rejected',
  queued_for_publishing: 'Queued for Publishing',
  published: 'Published',
  publishing_error: 'Publishing Error',
  delisted: 'Discontinued',
  published_with_cic_error: 'Published and Rejected by Subscriber',
  delist_requested: 'Delist Requested',
  awaiting_withdrawal: 'Awaiting Withdrawal',
  // 'deleted' : 'Deleted',
  awaiting_core_data_approval: 'Awaiting Brandbank Captured Data Review',
  outstanding_rejection: 'Brandbank Captured Data Rejected',
  awaiting_review: 'Action Required',
  hierarchy_withdrawal: 'Hierarchy Withdrawal',
  pending_live: 'Pending Live',
};

export const NBB_PRODUCT_STATUS = {
  Draft: 'draft',
  'Awaiting Approval': 'awaiting_approval',
  Rejected: 'rejected',
  'Queued for Publishing': 'queued_for_publishing',
  Published: 'published',
  'Delist Requested': 'delist_requested',
  'Awaiting Withdrawal': 'awaiting_withdrawal',
  Deleted: 'deleted',
  Delisted: 'delisted',
  'Publishing Error': 'publishing_error',
  'Action Required': 'awaiting_review',
  'Hierarchy Withdrawal': 'hierarchy_withdrawal',
  'Pending Live': 'pending_live',
  discarded: 'discarded',
};

export const UNIT_DESCRIPTOR_HIERARCHY = {
  TRANSPORT_LOAD: {
    parent: ['TRANSPORT_LOAD'],
    child: ['TRANSPORT_LOAD', 'PALLET', 'MIXED_MODULE', 'CASE', 'DISPLAY_SHIPPER', 'PACK_OR_INNER_PACK', 'BASE_UNIT_OR_EACH'],
  },
  PALLET: {
    parent: ['TRANSPORT_LOAD', 'PALLET', 'MIXED_MODULE'],
    child: ['PALLET', 'MIXED_MODULE', 'CASE', 'DISPLAY_SHIPPER', 'PACK_OR_INNER_PACK', 'BASE_UNIT_OR_EACH'],
  },
  MIXED_MODULE: {
    parent: ['TRANSPORT_LOAD', 'PALLET', 'MIXED_MODULE'],
    child: ['PALLET', 'MIXED_MODULE', 'CASE', 'DISPLAY_SHIPPER', 'PACK_OR_INNER_PACK', 'BASE_UNIT_OR_EACH'],
  },
  CASE: {
    parent: ['TRANSPORT_LOAD', 'PALLET', 'MIXED_MODULE', 'CASE', 'DISPLAY_SHIPPER'],
    child: ['CASE', 'DISPLAY_SHIPPER', 'PACK_OR_INNER_PACK', 'BASE_UNIT_OR_EACH'],
  },
  DISPLAY_SHIPPER: {
    parent: ['TRANSPORT_LOAD', 'PALLET', 'MIXED_MODULE', 'CASE', 'DISPLAY_SHIPPER'],
    child: ['CASE', 'DISPLAY_SHIPPER', 'PACK_OR_INNER_PACK', 'BASE_UNIT_OR_EACH'],
  },
  PACK_OR_INNER_PACK: {
    parent: ['TRANSPORT_LOAD', 'PALLET', 'MIXED_MODULE', 'CASE', 'DISPLAY_SHIPPER', 'PACK_OR_INNER_PACK'],
    child: ['PACK_OR_INNER_PACK', 'BASE_UNIT_OR_EACH'],
  },
  BASE_UNIT_OR_EACH: {
    parent: ['TRANSPORT_LOAD', 'PALLET', 'MIXED_MODULE', 'CASE', 'DISPLAY_SHIPPER', 'PACK_OR_INNER_PACK'],
    child: [],
  },
};

export const MAX_LENGTH_WARNING_MESSAGE = 'You have reached your maximum limit of characters allowed';
export const WS_CONNECT = ['WS-CONNECT+'];
export const WS_CONNECT_URL = 'https://dev.sso.madeby.ws/';

export const CONNECT_PLUS_TIMER = 1000 * 60; // 1 Minute

export const MANDATORY_METADATA_MESSAGE = 'This field cannot be empty';
export const MANDATORY_PARENTCLASS_MESSAGE = 'This field cannot be empty';
export const DUPLICATE_RETAILER_MESSAGE = 'You cannot have same retailer multiple times selected';
export const HEADER_MESSAGE = 'Heading cannot be empty';
export const GYEAR_METADATA_MESSAGE = 'Number must be greater than or equal to 1500';
export const GYEAR_MAX_METADATA_MESSAGE = 'Number must be lower than or equal to 3000';

export const HIERARCHY_CHANGE_ERROR_MESSAGE = 'This product contains child item(s) which have private publication type';
export const RECIPIENT_CHANGE_ERROR_MESSAGE = 'Updating the publication type or deletion of recipient(s) will remove the TPD data from the product. Are you sure you want to continue?';
export const RECIPIENT_DELETE_ERROR_MESSAGE = 'Unable to delete recipient. Parent recipient needs to be deleted first.';
export const DEPRECATE_WARNING_MESSAGE = 'You might loose the data that is deprecated. Are you sure you want to continue?';
export const EMPTY_PUBLICATION_TYPE_ERROR_MESSAGE = 'GDSN Publication Type should not be empty';

export const EMPTY_PUBLICATION_CHANNEL_ERROR_MESSAGE = 'Select at least one Publication Channel';

export const EMPTY_DATA_RECIPIENT = 'Add at least one recipient';

const COLOR_YELLOW = '#ffc000';
const COLOR_GRAY = '#808080';
const COLOR_LIGHTGREEN = '#92d050';
const COLOR_GREEN = '#00b050';
const COLOR_WHITE = '#ffffff';
const COLOR_RED = '#ba2121';
export const COLOR_STATUS = {
  awaiting_core_data_approval: [COLOR_YELLOW, COLOR_WHITE, COLOR_WHITE],
  outstanding_rejection: [COLOR_GRAY, COLOR_WHITE, COLOR_WHITE],
  awaiting_pre_approval: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  awaiting_review: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  in_progress: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  awaiting_approval: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  rejected: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  approval_processing: [COLOR_LIGHTGREEN, COLOR_LIGHTGREEN, COLOR_WHITE],
  pending_live: [COLOR_LIGHTGREEN, COLOR_LIGHTGREEN, COLOR_WHITE],
  approved: [COLOR_GREEN, COLOR_GREEN, COLOR_GREEN],
  delist_requested: [COLOR_GRAY],
  delisted: [COLOR_GRAY],
  error: [COLOR_RED, COLOR_RED, COLOR_RED],
};

export const NBB_COLOR_STATUS = {
  awaiting_core_data_approval: [COLOR_YELLOW, COLOR_WHITE, COLOR_WHITE],
  outstanding_rejection: [COLOR_GRAY, COLOR_WHITE, COLOR_WHITE],
  awaiting_pre_approval: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  awaiting_review: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  draft: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  awaiting_approval: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  rejected: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  queued_for_publishing: [COLOR_LIGHTGREEN, COLOR_LIGHTGREEN, COLOR_WHITE],
  pending_live: [COLOR_LIGHTGREEN, COLOR_LIGHTGREEN, COLOR_WHITE],
  published: [COLOR_GREEN, COLOR_GREEN, COLOR_GREEN],
  delist_requested: [COLOR_GRAY],
  delisted: [COLOR_GRAY],
  error: [COLOR_RED, COLOR_RED, COLOR_RED],
  publishing_error: [COLOR_RED, COLOR_RED, COLOR_RED],
  published_with_cic_error: [COLOR_GREEN, COLOR_GREEN, COLOR_GREEN],
  awaiting_withdrawal: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  action_required: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
  hierarchy_withdrawal: [COLOR_YELLOW, COLOR_YELLOW, COLOR_WHITE],
};

export const DELISTEDSTATUS = ['delisted', 'delist_requested'];

export const SUPPLIER_PREFERRED_PRODUCT_NAME_TOOLTIP_LENGTH = 1000;
export const NBB_IDENTITY_IMAGE_TYPE = 'T1_ID_SHOT';
export const NBB_HEIRARCHY_NAVIGATION_WARNING = 'You have unsaved product data. By clicking Continue your data will be saved and you will navigate to the selected product in the hierarchy. Are you sure you want to continue?';
export const NBB_ADD_UPPER_LEVEL_WARNING = 'Data will be lost if an upper-level item is added without saving the product data. Are you sure you want to continue?';

export const BB_WINS_EVEN_BLANK = 'This data will be replaced and locked for editing if Brandbank processes a certified product record.';
export const CHECK_MERGE_RULE = 'BB wins EVEN IF blank';
export const DISABLED_CHANNEL_MSG = 'Please contact your account manager to enable publication to this channel';

export const NBB_GLOBAL_ACTION_ITEMS = [
  { value: 'coreValue', label: 'Keep All Nielsen Brandbank Captured Values' },
  { value: 'lastSaveValue', label: 'Keep All Last Saved Values' },
  { value: '', label: 'Set All Values to Empty' },
];

export const NBB_ACTION_ITEMS = [
  { value: 'coreValue', label: 'Keep Nielsen Brandbank Captured Values' },
  { value: 'lastSaveValue', label: 'Keep Last Saved Values' },
  { value: '', label: 'Set Values to Empty' },
];

export const CONFLICT_HEAD_TEXT = {
  headerTooltip: 'For this product, you have previously made amendments to the attributes below. Brandbank have now captured the label data for the same attributes and there is an action for you to take.',
  mainHeadText: 'Please review the below data conflicts and select which values you wish to retain',
  supplier_header_text: 'Data you have previously entered and Brandbank have captured new information from the label. We recommend you retain the Last Saved Values.',
  nbb_header_text: 'Data you have previously entered and Brandbank have captured new information from the label. We recommend you retain the Brandbank Captured Values.',
};

export const CONFLICT_RULE_KEY = {
  swub: 'SUPPLIER_WINS_UNLESS_BLANK',
  bwub: 'BB_WINS_UNLESS_BLANK',
};

export const MEASUREMENT_UNIT_CODE = {
  kilogram: 'KGM',
  gram: 'GRM',
};

export const UNIT_DESCRIPTOR_LABEL = {
  TRANSPORT_LOAD: 'Transport Load',
  PALLET: 'Pallet',
  MIXED_MODULE: 'Display Ready Pallet',
  CASE: 'Case',
  DISPLAY_SHIPPER: 'Display Shipper',
  PACK_OR_INNER_PACK: 'Pack or Inner Pack',
  BASE_UNIT_OR_EACH: 'Base Unit or Each',
};

export const NBB_SUPPORTED_LANGUAGE = [
  { value: 'nl', label: 'Dutch (nl)' },
  { value: 'fr', label: 'French (fr)' },
  { value: 'en', label: 'English (en)' },
  { value: 'de', label: 'German (de)' },
];

export const REPORT_TYPES = {
  SUMMARY: 'summary-reports',
  DETAIL: 'detail-reports',
  MERGE: 'merge-report',
};

export const STRUCTURED_NUTRITION_TYPE = {
  STRUCTURED_NUTRITION_EU: 'Structured Nutrition EU',
  STRUCTURED_NUTRITION_US: 'Structured Nutrition US',
};

export const ITEM_STRUCTURED_NUTRITION = 'ItemStructuredNutrition';

export const NOTIFICATION_TIME_OUT = 10000;

// Block GDSN publication channel messages
export const NBB_TM_BLOCK_GDSN_CHANNEL_MSG = {
  BE: 'The data for this product was coded by NIQ Brandbank before 1st March 2021 and is unlikely to meet the current GS1 data entry rules. Please contact your Account Manager to arrange a revision of your product content to enable publication to the GDSN channel.',
  NL: 'The data for this product was coded by NIQ Brandbank before 5th June 2017 and is unlikely to meet the current GS1 data entry rules. Please contact your Account Manager to arrange a revision of your product content to enable publication to the GDSN channel.',
  PL: 'The data for this product was coded by NIQ Brandbank before January 2021 and is unlikely to meet the current GS1 data entry rules. Please contact your Account Manager to arrange a revision of your product content to enable publication to the GDSN channel.',
};

export const INVALID_GTIN_MSG = 'The product GTIN is not compliant for the GDSN channel.  Please contact your account manager to arrange a GDSN compliant Brandbank certified product to be created.';

export const IMAGE_TOPUP_ASSET_TYPES = {
  BRANDBANK_IMAGE: 'brandbankImage',
  USER_IMAGE: 'image',
  USER_VIDEO: ['videoFile', 'vimeoUrl', 'youtubeUrl'],
};

export const VIDEO_MAPPING_TYPE = {
  VIMEO: 'vimeoUrl',
  YOUTUBE: 'youtubeUrl',
  MP4: 'videoFile',
};

export const VIDEO_SOURCE_TYPES = [
  { label: 'Vimeo', value: 'vimeoUrl' },
  { label: 'YouTube', value: 'youtubeUrl' },
  { label: 'MP4', value: 'videoFile' },
];

export const IMAGE_TOPUP_ASSET_SHOT_TYPE_ID = 'T1010';
export const VIMEO_URL_REGEX = 'https?:\/\/((player|www)\.)?vimeo.com\/.+';
export const YOUTUBE_URL_REGEX = '^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$';
export const IMAGE_TOPUP_ASSET_UPLOAD_LIMIT = 25;
export const IMAGE_TOPUP_LAYOUT_TYPE = {
  IMAGE_LAYOUT: 'imageLayout',
  VIDEO_LAYOUT: 'videoLayout',
};

export const VIDEO_EMBED_BASE_URL = 'https://player.vimeo.com/video';
export const VIMEO_OEMBED_URL = (url) => `https://vimeo.com/api/oembed.json?url=${url}&width=85&height=85`;
export const YOUTUBE_OEMBED_URL = (url) => `https://youtube.com/oembed?url=${url}&format=json`;
export const CONNECT_MAX_TOOLTIP = {
  ENABLED_LAYOUT: 'This is a count of how many layouts you currently have Enabled. Selecting this dropdown will show you all available retailer layouts and which layouts you have currently Enabled.',
  SWITCH_INPUT: 'This toggle illustrates whether the chosen layout is Enabled/Disabled.',
  AVAILABLE_SLOTS_DEFAULT: `When the default layout is enabled, this will be pre-populated with the NIQ Brandbank processed images in number order.
  This asset order is not illustrative of what the retailer's have selected.
  Once you have Enabled, updated and saved your layout, this layout will take precedence based on your preference.`,
  AVAILABLE_SLOTS_RETAILER: `When the retailer specific layout is enabled, this will be pre-populated with the asset order of the Default layout (if Enabled), up to the maximum available asset slots for the specific retailer.
  If the Default layout is disabled, the retailer specific layout will be pre-populated based on the images selected by the retailer.`,
  BRANDBANK_LIBRARY: 'In this section, you will see all assets available that have been processed by NIQ Brandbank.',
  USER_LIBRARY: 'In this section, you can upload image and video assets to be used in your layouts.',
};

export const CONNECT_MAX_MESSAGE = {
  DEFAULT_DISABLE_P1: 'Retailers have varying asset preferences and abilities when it comes to ecommerce display; as a result, they currently receive assets based on what they have selected. The drop-down option for each retailer will show you exactly how many assets they can display online.',
  DEFAULT_DISABLE_P2: 'You can view and customise the available assets for each retailer by navigating to their respective layouts. You have the option to either use the default layout or enable and customise individual retailer layouts based on your preference.',
  ENABLE_DEFAULT_LAYOUT: 'This default layout will be used for all retailers who do not have a retailer specific layout enabled.',
  DEFAULT_DISABLE_RETAILER_ENABLE: 'This layout can now be customised to your preference. Disable this to revert to the retailer\'s selection.',
  DEFAULT_ENABLE_RETAILER_ENABLE: 'This layout is specific to this retailer. Disable this to revert to the default layout.',
  RETAILER_DISABLE_DEFAULT_DISABLE: 'This retailer is currently receiving its assets based on what they have selected, as seen below. Customise this to modify the layout based on your preference.',
  DEFAULT_ENABLE_RETAILER_DISABLE: 'This retailer is currently receiving its assets from the default layout. Enable this to create a layout specific to this retailer.',
};

export const CONNECT_MAX_ASSET_FILE_SIZE_MESSAGE = 'File size must be smaller or equal to';

export const COMPETITOR_COLOR_CODE = [
  '4FBE11',
  '4FBE11',
  '4FBE11',
  'AEE228',
  'AEE228',
  'ECDB23',
  'F6961E',
  'FF471A',
  'FF471A',
  'FF471A',
  'FF471A',
];

export const SPEEDOMETER_COLOR_CODE = [
  '#FF7553',
  '#F8B056',
  '#F1E45A',
  '#C2E95E',
  '#8FE162',
];

export const WIDGET_TOOLTIP_PLACEHOLDER = {
  COMPLETENESS_WIDGET: 'completeness_widget_tooltip',
  HEALTH_WIDGET: 'health_widget_tooltip',
  RANK_WIDGET: 'rank_widget_tooltip',
  PRODUCT_STATUS_WIDGET: 'product_status_widget_tooltip',
  RSC_WIDGET: 'retailer_content_widget_tooltip',
};

export const CHP_MODAL_TOOLTIP_PLACEHOLDER = {
  COMPLETENESS_TOOLTIP: 'completeness_modal_tooltip',
  QUALITY_TOOLTIP: 'quality_modal_tooltip',
  QUALITY_ATTRIBUTE_TOOLTIP: 'quality_attribute_tooltip',
  IMAGE_QUALITY_TOOLTIP: 'image_attribute_tooltip',
};

export const CHP_SCORE_PROCESSING_STATUS = {
  SUCCESS: 0,
  IN_PROGRESS: 1,
  FAILED: 2,
  PENDING: 3,
};

/**
 * this logic originates from local GS1 Benelux validation ID 500.537 / VR_BASIC_0079
 */
export const BENELUX_2ND_TO_4TH_GTIN_DIGITS = ['014', '015', '016', '017', '018', '019', '381', '382', '384', '386', '388', '039', '441', '442', '443', '444', '445', '446', '447', '448', '449', '472', '473', '051', '522', '523', '524', '525', '526', '527', '532', '533', '534', '536', '537', '538', '055', '561', '562', '563', '564', '565', '566', '567', '568', '58', '591', '592', '593', '595', '596', '597', '598', '602', '612', '632', '633', '634', '635', '636', '637', '638', '639', '065', '066', '067', '682', '683', '684', '685', '686', '687', '688', '689', '071', '720', '721', '722', '723', '724', '725', '726', '727', '728', '747', '748', '749', '751', '752', '753', '756', '757', '772', '774', '776', '781', '782', '783', '785', '787', '788', '791', '792', '793', '794', '795', '796', '797', '798', '799', '851', '852', '853', '854', '855', '856', '857', '861', '862', '863', '864', '866', '882', '886', '887', '889', '891', '892', '895', '897', '898', '092', '952', '953', '954', '956', '957', '959', '970', '971', '972', '973', '974', '975', '976', '984', '985', '986', '987', '988', '989'];

export const DATA_EXTRACT_INITIAL_SELECTED_ITEMS = ['gtin', 'description', 'targetMarket', 'language', 'retailer', 'multipart'];
export const INITIAL_WILD_CARDS = ['ProductCode', 'ShotTypeId', 'SubscriptionCode'];
export const MAX_WILD_CARD_LIMIT = 5;
export const FILE_NAME_PREVIEW = {
  DPI: '72',
  ProductCode: '500050000001',
  ProductCodePadded: '00500050000001',
  GeneratedTimestamp: '05092024_1648',
  ShotTypeId: 'T1',
  PixelHeight: '500',
  PixelWidth: '500',
  ProductDescription: 'ProductDescription',
  PVID: '1234567',
  SubscriptionCode: 'ABCD001',
  ShotTypeIdNumberOnly: '1'
}

export const IMAGE_EXPORT_PROCESS_STATUS_MESSAGES = {
  failed: 'There are no matching assets available for download against the following products:',
  failed_nbb: 'The assets of the requested product(s) failed to download.',
  partial_success: 'Some assets of the requested product(s) failed to download.',
  success: 'File is ready to download for the following products:'
}

export const PREVIEW_MAX_SIZE = 4500;
export const PREVIEW_MIN_SIZE = 50;
