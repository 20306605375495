import { call, put } from 'redux-saga/effects';

import axiosApiCall from '../../../../constant/axiosApiCall';
import { GET_COMMENTS_SUCCESS_REQUEST, GET_COMMENTS_FAILURE_REQUEST } from '../../../actions/Actions';
import getIdToken from '../../../../Utils/getIdToken';

/* -----------------------------------GET-COMMENTS----------------------------------*/
export async function GetCommentsList(url, data, token) {
  return axiosApiCall.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function* getCommentsListSaga(action) {
  try {
    const token = yield call(getIdToken, action.data.auth0);
    const response = yield call(GetCommentsList, action.data.routeName, action.data, token);
    const commentsList = response.data.data;
    yield put({ type: GET_COMMENTS_SUCCESS_REQUEST, commentsList });
  } catch (error) {
    yield put({ type: GET_COMMENTS_FAILURE_REQUEST, error });
  }
}
